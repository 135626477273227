import { ITestimonialProps } from "./TestimonialItem";

export const testimonials: ITestimonialProps[] = [
    {
      id: "1",
      name: "Alex Johnson",
      review: "The pastries here are absolutely divine. I’ve never had anything quite like their chocolate croissant!",
      role: "Customer",
      avatar: "/avatars/alex-johnson.png",
      rating: 5,
      postDate: "2022-07-14",
    },
    {
      id: "2",
      name: "Samantha Bloom",
      review: "A gem in the neighborhood. The bread is always fresh, and the staff is incredibly friendly.",
      role: "Regular Customer",
      avatar: "/avatars/samantha-bloom.png",
      rating: 5,
      postDate: "2022-08-05",
    },
    {
      id: "3",
      name: "Ethan Winters",
      review: "Working at this bakery has been an amazing experience. The team is like a family, and we all share a passion for baking.",
      role: "Baker",
      avatar: "/avatars/ethan-winters.png",
      rating: 5,
      postDate: "2022-09-01",
    },
    {
      id: "4",
      name: "Olivia King",
      review: "Their custom cake exceeded my expectations! It was the highlight of the birthday party and tasted as good as it looked.",
      role: "Customer",
      avatar: "/avatars/olivia-king.png",
      rating: 5,
      postDate: "2022-10-12",
    },
    {
      id: "5",
      name: "Daniel Rivera",
      review: "The cozy atmosphere and delicious coffee make this my go-to spot for morning meetings and relaxation.",
      role: "Frequent Visitor",
      avatar: "/avatars/daniel-rivera.png",
      rating: 4,
      postDate: "2022-11-23",
    },
  ];
  