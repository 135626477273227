import React from "react";
import { useAppRegistry } from "../app-context";
import { NotFoundView } from "lib-mui-template";
import { useLocation } from 'react-router-dom';
import { useHelmet } from "../lib/helmet/lib-helmet";

const Error404Page: React.FC = () => {
  const location = useLocation();
  const fromPath = location.state?.from || 'Unknown path';

  const Helmet = useHelmet({ title: "404 Page Not Found" });
  return (
    <>
      <Helmet />
      {/* {helmet?.render({ title: "404 Page Not Found" })} */}
      <NotFoundView fromPath={fromPath} />
    </>
  );
};

export default Error404Page;
