import { useState } from "react";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Stack,
  Button,
  Divider,
  Container,
  Typography,
  Unstable_Grid2 as Grid,
  useTheme,
} from "@mui/material";
import { m, MotionProps } from "framer-motion";
import {
  Iconify,
  MotionContainer,
  bgGradient,
  useResponsive,
  useSettingsContext,
  varBgColor,
  varContainer,
  varFade,
  varHover,
  varPath,
  varSlide,
  varTranEnter,
} from "lib-mui-template";

import { BoxProps } from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import LogoView from "./LogoView";
import TestimonialMarketing from "./TestimonialMarketing";

import { testimonials } from "./fixture";

const StyledRoot = styled(Stack)(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.grey[900], 0.4),
    imgUrl:
      "https://saborysaludable.com/es/images/3abd1655a7ffbbe02f07b1b25986da83.jpg",
  }),
  overflow: "hidden",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(15),
  },
}));

const StyledBar = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up("md")]: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

export default function LandingPage() {
  const isMdUp = useResponsive("up", "md");

  const theme = useTheme();

  const settings = useSettingsContext();

  const generateTitle = (
    <Container component={MotionContainer}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid xs={12} md={6} lg={5}>
          <Stack
            spacing={5}
            sx={{
              textAlign: { xs: "center", md: "unset" },
            }}
          >
            <Stack spacing={3}>
              <m.div variants={varFade().inRight}>
                <Typography variant="h1" sx={{ color: "common.white" }}>
                  Home Bakery 
                  <Box component="span" sx={{ color: "primary.main" }}>
                    {` Free From `}
                  </Box>
                  Allergens
                </Typography>
              </m.div>
              <Stack
                spacing={3}
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "center", md: "unset" }}
                justifyContent={{ xs: "center", md: "unset" }}
                sx={{ mt: 5 }}
              >
                <Button variant="contained" color="primary" size="large">
                  Our Offer
                </Button>

                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ typography: "h6" }}
                >
                  <Tooltip title="Delete">
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<Iconify icon="carbon:play" />}
                    >
                      How to Order
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        {isMdUp && (
          <Grid xs={12} md={6} lg={6}>
            {/* <LogoView /> */}
          </Grid>
        )}

        {/* {isMdUp && (
        <Grid xs={12} md={6} lg={6}>
          <CareerHeroIllustration />
        </Grid>
      )} */}
      </Grid>
    </Container>
  );

  const generateAnimeTitle = (
    <Container component={MotionContainer}>
      <Box
        sx={{
          bottom: { md: 40 },
          position: { md: "absolute" },
          textAlign: {
            xs: "center",
            md: "unset",
          },
        }}
      >
        <TextAnimate
          text="Who"
          variants={varFade().inRight}
          sx={{ color: "primary.main" }}
        />

        <br />

        <Stack
          spacing={2}
          display="inline-flex"
          direction="row"
          sx={{ color: "common.white" }}
        >
          <TextAnimate text="we" />
          <TextAnimate text="are?" />
        </Stack>

        <m.div variants={varFade().inRight}>
          <Typography
            variant="h4"
            sx={{
              mt: 3,
              color: "common.white",
              fontWeight: "fontWeightSemiBold",
            }}
          >
            Let&apos;s work together and
            <br /> make awesome site easily
          </Typography>
        </m.div>
      </Box>
    </Container>
  );

  return (
    <>
      <StyledRoot>
        {/* {generateAnimeTitle} */}
        {generateTitle}
      </StyledRoot>
      <Container>
        <TestimonialMarketing testimonials={testimonials} />
      </Container>
    </>
  );
}

// <BrandsSection />
// <SummarySection />
// <StyledBar spacing={{ xs: 1, md: 0 }}>
// {/* <CareerFilterKeyword
//     filterKeyword={filters.filterKeyword}
//     onChangeKeyword={handleChangeKeyword}
//     sx={{
//       bgcolor: 'transparent',
//       '&:hover, &.Mui-focused': { bgcolor: 'transparent' },
//     }}
//   /> */}

// {isMdUp && (
//   <Divider orientation="vertical" sx={{ height: 24 }} />
// )}
// {/*
//   <CareerFilterLocations
//     filterLocation={filters.filterLocation}
//     onChangeLocation={handleChangeLocation}
//     sx={{
//       bgcolor: 'transparent',
//       '&:hover, &.Mui-focused': { bgcolor: 'transparent' },
//     }}
//   /> */}

// <Button
//   size="large"
//   variant="contained"
//   sx={{
//     px: 0,
//     minWidth: { xs: 1, md: 48 },
//   }}
// >
//   <Iconify icon="carbon:search" width={24} />
// </Button>
// </StyledBar>

type TextAnimateProps = BoxProps &
  MotionProps & {
    text: string;
  };

function TextAnimate({ text, variants, sx, ...other }: TextAnimateProps) {
  return (
    <Box
      component={m.div}
      sx={{
        typography: "h1",
        overflow: "hidden",
        display: "inline-flex",
        ...sx,
      }}
      {...other}
    >
      {text.split("").map((letter, index) => (
        <m.span key={index} variants={variants || varFade().inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
}
