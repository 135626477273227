import { Dayjs } from "dayjs";
import { Order, OrderSnapshot } from "lib-shared-code";

export function toOrderSnapshot(order: Order): OrderSnapshot {
  return {
    orderId: order.id,
    orderDate: order.orderDate,
    subTotal: order.subTotal,
    totalAmount: order.totalAmount,
    totalItems: order.totalItems,
    discount: order.discount,
    shipping: order.shipping,
  };
}

export function getFirstAndLastOrderDates(lastOrders?: OrderSnapshot[]): {
    firstOrderDate: Dayjs | null;
    lastOrderDate: Dayjs | null;
} {
    if (!lastOrders || lastOrders.length === 0) {
        return { firstOrderDate: null, lastOrderDate: null };
    }

    const sortedOrders = lastOrders.sort((a, b) => a.orderDate.diff(b.orderDate));
    const firstOrderDate = sortedOrders[0].orderDate;
    const lastOrderDate = sortedOrders[sortedOrders.length - 1].orderDate;

    return { firstOrderDate, lastOrderDate };
}
