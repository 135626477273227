
import { ProductRepository, ProductDisplayRepository } from "src/app/data/repositories";
import { ProductDisplayBusinessLogic } from "./app-business-logic";
import { getProductRepository, getProductDisplayRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";
import { ProductDisplay, createEmptyProductDisplayFromProduct } from "lib-shared-code";

export default class ProductDisplayBusinessLogicImpl implements ProductDisplayBusinessLogic {
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new ProductDisplayBusinessLogicImpl(
      getProductDisplayRepository(db, bakeryIdGetter),
      getProductRepository(db, bakeryIdGetter),
    );
  }

  constructor(
    private readonly productDisplayRepository: ProductDisplayRepository,
    private readonly productRepository: ProductRepository,
    ) {
    if (productDisplayRepository === null) {
      throw new Error("productDisplayRepository is null");
    }
  }

  getProductDisplays = async (): Promise<ProductDisplay[]> => {
    return this.productDisplayRepository.findAll();
  };

  addProductDisplay = async (productDisplay: Omit<ProductDisplay, "id">): Promise<string> => {
    return this.productDisplayRepository.createById(productDisplay.productId, productDisplay);
  };

  updateProductDisplay = async (
    id: string,
    productDisplay: Partial<ProductDisplay>
  ): Promise<void> => {
    console.log("updateProductDisplay", id, productDisplay)
    return this.productDisplayRepository.update(id, productDisplay);
  };

  deleteProductDisplay = async (id: string): Promise<void> => {
    return this.productDisplayRepository.deleteById(id);
  };

  getProductDisplay = async (id: string): Promise<ProductDisplay | null> => {
    return this.productDisplayRepository.findById(id);
  };

  getOrCreateProductDisplay = async (productId: string): Promise<ProductDisplay> => {
    try {
      const result = await this.productDisplayRepository.findById(productId);
      if (result) return result;
    } catch (error) {
      // We assume that the error is due to the productDisplay not being found
    }
    const product = await this.productRepository.findByIdOrThrowError(productId);
    const productDisplay = createEmptyProductDisplayFromProduct(product);
    const id =  await this.productDisplayRepository.createById(productId,productDisplay); 
    productDisplay.id = id;
    return productDisplay 
  }
}
