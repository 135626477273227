import { Task, TaskStatus } from "lib-shared-code";
import { TaskRepository } from "src/app/data/repositories";
import { TaskBusinessLogic } from "./app-business-logic";
import { getTaskRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";

export default class TaskBusinessLogicImpl implements TaskBusinessLogic {
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new TaskBusinessLogicImpl(
      getTaskRepository(db, bakeryIdGetter)
    );
  }

  constructor(private readonly taskRepository: TaskRepository) {
    if (taskRepository === null) {
      throw new Error("taskRepository is null");
    }
  }

  getTasks = async (): Promise<Task[]> => {
    return this.taskRepository.findAll();
  };

  addTask = async (task: Omit<Task, "id">): Promise<string> => {
    return this.taskRepository.create(task);
  };

  updateTask = async (
    id: string,
    task: Partial<Task>
  ): Promise<void> => {
    return this.taskRepository.update(id, task);
  };

  updateTaskStatus = async (
    id: string,
    newStatus: TaskStatus
  ): Promise<void> => {
    const statusUpdate: Partial<Task> = {status: newStatus};
    return this.taskRepository.update(id, statusUpdate);
  };

  deleteTask = async (id: string): Promise<void> => {
    return this.taskRepository.deleteById(id);
  };

  getTask = async (id: string): Promise<Task | null> => {
    return this.taskRepository.findById(id);
  };
}
