import { useHelmet } from "src/app/lib/helmet/lib-helmet";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  CustomBreadcrumbs,
  Iconify,
  useSettingsContext,
} from "lib-mui-template";
import { paths } from "src/app/navigation/paths";
import ProductDetailsCarousel from "./ProductDetailsCarousel";
import ProductDetailsSummary from "./ProductDetailsSummary";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

const SUMMARY = [
  {
    title: "100% Original",
    description: "Chocolate bar candy canes ice cream toffee cookie halvah.",
    icon: "solar:verified-check-bold",
  },
  {
    title: "10 Day Replacement",
    description: "Marshmallow biscuit donut dragée fruitcake wafer.",
    icon: "solar:clock-circle-bold",
  },
  {
    title: "Year Warranty",
    description: "Cotton candy gingerbread cake I love sugar sweet.",
    icon: "solar:shield-check-bold",
  },
];


export type IProductItem = {
  id: string;
  ingredients: string;
  sku: string;
  name: string;
  code: string;
  price: number;
  taxes: number;
  tags: string[];
  gender: string;
  sizes: string[];
  publish: string;
  coverUrl: string;
  images: string[];
  colors: string[];
  quantity: number;
  category: string;
  available: number;
  totalSold: number;
  description: string;
  totalRatings: number;
  totalReviews: number;
  inventoryType: string;
  subDescription: string;
  priceSale: number | null;

  createdAt: Date;
  ratings: {
    name: string;
    starCount: number;
    reviewCount: number;
  }[];
  saleLabel: {
    enabled: boolean;
    content: string;
  };
  newLabel: {
    enabled: boolean;
    content: string;
  };
  freeFrom: { icon: string; label: string }[];
  variants: { name: string; price: number }[];
  labels: { label: string; color: string , tooltip:string}[];
};


const productItemFixture: IProductItem = {
  id: "1",
  subDescription:
    "Our oat bread with seeds is easy to digest and 100% gluten-free. It has a crispy crust and a soft, elastic interior. It doesn't crumble when sliced. Oat flour has a characteristic consistency and flavor, giving the bread a nutty tone and delicacy. It does not contain colorants, flavorings, or bread improvers. The addition of seeds provides nutritional value and a wonderful taste.",
  ingredients:
    "Organic certified gluten-free whole grain oat flour, potato starch, tapioca starch, rice flour, psyllium husk, sunflower seeds, pumpkin seeds, sesame seeds, flaxseeds, poppy seeds, fresh yeast, salt, water.",
  sku: "SKU12345",
  name: "Oat Bread",
  code: "CS123",
  price: 5,
  taxes: 19.99,
  tags: ["casual", "sneakers", "unisex"],
  gender: "unisex",
  sizes: ["S", "M", "L"],
  publish: "published",
  coverUrl:
    "https://saborysaludable.com/es/images/060a7b92052e1c7f8823516f0c5cfa10.jpg",
  images: [
    "https://saborysaludable.com/es/images/060a7b92052e1c7f8823516f0c5cfa10.jpg",
    "https://saborysaludable.com/es/images/060a7b92052e1c7f8823516f0c5cfa10.jpg",
    "https://saborysaludable.com/es/images/060a7b92052e1c7f8823516f0c5cfa10.jpg",
  ],
  colors: ["black", "white"],
  quantity: 100,
  category: "footwear",
  available: 80,
  totalSold: 20,
  description: "A pair of cool sneakers perfect for casual wear.",
  totalRatings: 5,
  totalReviews: 2,
  inventoryType: "in_stock",
  priceSale: null,

  createdAt: new Date(),
  ratings: [
    {
      name: "John Doe",
      starCount: 5,
      reviewCount: 1,
    },
    {
      name: "Jane Doe",
      starCount: 4,
      reviewCount: 1,
    },
  ],
  saleLabel: {
    enabled: true,
    content: "Sale!",
  },
  newLabel: {
    enabled: true,
    content: "aaa",
  },
  /*
  Celiac-Safe"
"Vegan"
"Low-GI"
"High-Fiber"
  */
  labels: [
    { label: "Celiac-Safe", color: "info", tooltip: "This product is safe for people with celiac disease."},
    { label: "Vegan", color: "success", tooltip: "This product is suitable for vegans." },
    { label: "Low-GI", color: "warning", tooltip: "This product has a low glycemic index."},
    { label: "High-Fiber", color: "primary" , tooltip: "This product is high in fiber."},
  ],
  variants: [
    { name: "500g", price: 5 },
    { name: "750g", price: 7 },
    { name: "1000g", price: 9 },

  ],
  // freeFrom: [
  //   { icon: "mdi:gluten-free", label: "Gluten" },
  //   // {icon: "healthicons:gluten-free-outline", label: "Gluten"},
  //   { icon: "mdi:dairy-free", label: "Dairy" },
  //   { icon: "mdi:egg-off-outline", label: "Egg" },
  //   { icon: "mdi:soy-sauce-off", label: "Soy" },
  //   { icon: "mdi:palm-tree", label: "Palm Oil" },
  //   { icon: "mdi:sugar-cube-off", label: "Refined Sugar" },
  //   { icon: "mdi:corn-off", label: "Corn" },
  // ],
  freeFrom: [
    { icon: "mdi:gluten-free", label: "Gluten-Free" },
    { icon: "mdi:dairy-free", label: "Dairy-Free" },
    { icon: "mdi:egg-off-outline", label: "Egg-Free" },
    { icon: "mdi:soy-sauce-off", label: "Soy-Free" },
    // { icon: "mdi:palm-tree", label: "Palm Oil-Free" },
    { icon: "mdi:sugar-cube-off", label: "Refined Sugar-Free" },
    { icon: "mdi:corn-off", label: "Corn-Free" },
  ],
};

//  gluten, dairy, eggs, soy, palm oil, refined sugar
export default function BreadPage() {
  const settings = useSettingsContext();
  const title = "Edit Order";
  const Helmet = useHelmet({ title });
  return (
    <>
      <Helmet />
      {/* <Container maxWidth={settings.themeStretch ? false : "lg"}> */}
      <Container
        maxWidth={settings.themeStretch ? false : "lg"}
        sx={{
          mt: 5,
          mb: 15,
        }}
      >
        <CustomBreadcrumbs
          links={[
            { name: "Home", href: "/" },
            {
              name: "Shop",
              href: paths.dashboard.products.root,
            },
            { name: "Italian Bread" },
          ]}
          sx={{ mb: 5 }}
        />

        <Grid container spacing={{ xs: 3, md: 5, lg: 8 }}>
          <Grid xs={12} md={6} lg={7} item>
            <ProductDetailsCarousel product={productItemFixture} />
          </Grid>

          <Grid xs={12} md={6} lg={5} item>
            <ProductDetailsSummary
              disabledActions
              product={productItemFixture}
            />
          </Grid>
        </Grid>

        <Card>
          {/* <ProductDetailsDescription description={product?.description} /> */}
        </Card>
      </Container>
    </>
  );
}
