import { initializeApp } from "firebase/app";
import AppDecorator from "./app-decorator";
import AppView from "./app-view";
import AuthProviderFactory, {
  FirebaseAuthProviderFactory,
} from "./auth/auth-provider-factory";
import { FirebaseConfig } from "./lib/firebase/types";
import { LibHelmet } from "./lib/helmet/lib-helmet";

import { ReactNode } from "react";
import {
  BusinessLogic,
  createBusinessLogicRegistry,
} from "./app-business-logic";
import "./lib/yup-costumise";
import { Firestore } from "firebase/firestore";

export interface AppRegistry {
  businessLogic: BusinessLogic;
  helmet: LibHelmet | null;
}

export type AppConfig = {
  // basename?: string;
  firebaseConfig: FirebaseConfig;
};

// FIXME: add error handling visible for user
class App implements AppRegistry {
  static create(
    firebaseConfig: FirebaseConfig,
    firestoreCreatedCallback?: (firestore: Firestore) => void
  ) {
    const app = initializeApp(firebaseConfig);
    const claimsState = { userRole: "", userBakeryId: ""}
    const bakeryIdGetter =  () => {
      return claimsState.userBakeryId;
    }
    const onUserClaimsChanged = (claims: {
      userRole: string;
      userBakeryId: string;
    })  => {
      claimsState.userRole = claims.userRole;
      claimsState.userBakeryId = claims.userBakeryId;
    }
    const authProviderFactory = new FirebaseAuthProviderFactory(app,onUserClaimsChanged );
    
    const businessLogic = createBusinessLogicRegistry(
      app,
      firestoreCreatedCallback,
      bakeryIdGetter

    );
    return new App(authProviderFactory, businessLogic);
  }

  private decorators: AppDecorator[] = [];
  readonly view: AppView;
  readonly app: App;
  helmet: LibHelmet | null = null;
  authProviderNode?: ({ children }: { children: ReactNode }) => JSX.Element;

  constructor(
    readonly authProviderFactory: AuthProviderFactory,
    readonly businessLogic: BusinessLogic
  ) {
    this.app = this;
    this.view = new AppView(this);
  }

  start() {
    this.view.render(this.decorators, this.authProviderFactory);
  }

  andWithHelmet(helmet: LibHelmet) {
    this.helmet = helmet;
    this.addDecoraterIfWith(helmet);
    return this;
  }

  private addDecoraterIfWith(lib: AppDecorator) {
    this.decorators.push(lib);
  }
}

export default App;
