import { LoadingScreen } from "lib-mui-template";
import { Suspense } from "react";
import MainLayout from "src/app/layouts/main";
import PlaceHolderPage from "src/app/pages/PlaceHolderPage";

import { Link, Navigate, Outlet, useRoutes } from "react-router-dom";
import { mainHeaderMenu } from "src/app/navigation/sections/main-menu";
import HomePage from "src/app/pages/main/HomePage";
import BreadPage from "src/app/pages/main/bread/BreadPage";
import BreadsPage from "src/app/pages/main/breads/BreadsPage";
import LandingPage from "src/app/pages/main/landing/LandingPage";
import CompactLayout from "src/app/layouts/compact";
import SimpleLayout from "src/app/layouts/simple";

export const mainRoutes = [
  {
    path: "",
    element: (
      <MainLayout headerMenu={mainHeaderMenu} headerOnDark={false} >
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { index: true, element: <HomePage /> },
      //   { path: paths.placeHolder, element: <PlaceHolderPage /> },
      { path: "contact", element: <PlaceHolderPage /> },
      { path: "bread", element: <BreadPage /> },
      { path: "breads", element: <BreadsPage /> },
    ],
  },
  {
    path: "landing",
    element: (
      <MainLayout headerMenu={mainHeaderMenu} headerOnDark={true}>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [{ index: true, element: <LandingPage /> }],
  },
];
