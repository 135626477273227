import { useMemo, useEffect, useReducer, useCallback } from "react";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getFirestore,
} from "firebase/firestore";
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  connectAuthEmulator,
} from "firebase/auth";

import { AuthContext } from "./auth-context";
import { AuthUserType, ActionMapType, AuthStateType } from "../../types";
import { FirebaseApp } from "firebase/app";

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: Action) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  firebaseApp: FirebaseApp;
  onUserClaimsChanged: (claims: { userRole: string; userBakeryId: string }) => void;
};

export function AuthProvider({ children, firebaseApp, onUserClaimsChanged }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const { firebaseApp } = useAppRegistry();

  const AUTH = getAuth(firebaseApp);
  const DB = getFirestore(firebaseApp);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        // console.info("onAuthStateChanged", user);
        if (user) {
          user
            .getIdTokenResult()
            .then((idTokenResult) => {
              // Access custom claims
              const claims = idTokenResult.claims;
              // console.info("claims", claims);
              // For example, if you have a custom claim named 'role'
              const userRole = claims.role as string;
              const userBakeryId = claims.bakeryId as string;
              onUserClaimsChanged({userRole, userBakeryId});
              // You can use these claims to adjust the UI accordingly
              if (userRole === "admin") {
                // Show admin related UI
              }
            })
            .catch((error) => {
              console.error(error);
            });

          if (!user.emailVerified) {
            if (user) {
              sendEmailVerification(user)
                .then(() => {
                  // Email verification sent!
                  console.info("Verification email sent.");
                })
                .catch((error) => {
                  // An error occurred
                  console.error("Error sending verification email:", error);
                });
            }
          }
          if (user.emailVerified) {
            const userProfile = doc(DB, "users", user.uid);

            const docSnap = await getDoc(userProfile);

            const profile = docSnap.data();

            dispatch({
              type: Types.INITIAL,
              payload: {
                user: {
                  ...user,
                  ...profile,
                  id: user.uid,
                  role: "admin",
                },
              },
            });
          } else {
            dispatch({
              type: Types.INITIAL,
              payload: {
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  /*
   * (1) If skip emailVerified
   * Remove the condition (if/else) : user.emailVerified
   */
  /*
  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userProfile = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userProfile);

          const profile = docSnap.data();

          dispatch({
            type: Types.INITIAL,
            payload: {
              user: {
                ...user,
                ...profile,
                id: user.uid,
                role: 'admin',
              },
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);
*/

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithGithub = useCallback(async () => {
    const provider = new GithubAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithTwitter = useCallback(async () => {
    const provider = new TwitterAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      const newUser = await createUserWithEmailAndPassword(
        AUTH,
        email,
        password
      );

      /*
       * (1) If skip emailVerified
       * Remove : await sendEmailVerification(newUser.user);
       */
      await sendEmailVerification(newUser.user);

      const userProfile = doc(collection(DB, "users"), newUser.user?.uid);

      await setDoc(userProfile, {
        uid: newUser.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  /*
   * (1) If skip emailVerified
   * const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';
   */
  const checkAuthenticated = state.user?.emailVerified
    ? "authenticated"
    : "unauthenticated";

  const status = state.loading ? "loading" : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: "firebase",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithGithub,
      loginWithTwitter,
    }),
    [
      status,
      state.user,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGithub,
      loginWithGoogle,
      loginWithTwitter,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
