export type InputValue = string | number | null;

export function fCurrency(inputValue: InputValue) {
  const { code, currency } = { code: "es-ES", currency: "EUR" };

  if (!inputValue) return "";

  const number = Number(inputValue);

  const fm = new Intl.NumberFormat(code, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);

  return fm;
}

export function fPercent(inputValue: InputValue) {
  const { code } = { code: "es-ES" };

  if (!inputValue) return "";

  const number = Number(inputValue) / 100;

  const fm = new Intl.NumberFormat(code, {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(number);

  return fm;
}

export function fShortenNumber(inputValue: InputValue) {
  // const { code } = getLocaleCode();
  const { code, currency } = { code: "es-ES", currency: "EUR" };
  if (!inputValue) return '';

  const number = Number(inputValue);

  const fm = new Intl.NumberFormat(code, {
    notation: 'compact',
    maximumFractionDigits: 2,
  }).format(number);

  return fm.replace(/[A-Z]/g, (match) => match.toLowerCase());
}
