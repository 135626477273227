
import { ProductRepository, RecipeRepository } from "src/app/data/repositories";
import { RecipeBusinessLogic } from "./app-business-logic";
import { getProductRepository, getRecipeRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";
import { Recipe, createEmptyRecipeFromProduct } from "lib-shared-code";

export default class RecipeBusinessLogicImpl implements RecipeBusinessLogic {
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new RecipeBusinessLogicImpl(
      getRecipeRepository(db, bakeryIdGetter),
      getProductRepository(db, bakeryIdGetter),
    );
  }

  constructor(
    private readonly recipeRepository: RecipeRepository,
    private readonly productRepository: ProductRepository,
    ) {
    if (recipeRepository === null) {
      throw new Error("recipeRepository is null");
    }
  }

  getRecipes = async (): Promise<Recipe[]> => {
    return this.recipeRepository.findAll();
  };

  addRecipe = async (recipe: Omit<Recipe, "id">): Promise<string> => {
    return this.recipeRepository.create(recipe);
  };

  updateRecipe = async (
    id: string,
    recipe: Partial<Recipe>
  ): Promise<void> => {
    console.log("updateRecipe", id, recipe)
    return this.recipeRepository.update(id, recipe);
  };

  deleteRecipe = async (id: string): Promise<void> => {
    return this.recipeRepository.deleteById(id);
  };

  getRecipe = async (id: string): Promise<Recipe | null> => {
    return this.recipeRepository.findById(id);
  };

  getOrCreateRecipe = async (productId: string): Promise<Recipe> => {
    try {
      const result = await this.recipeRepository.findById(productId);
      if (result) return result;
    } catch (error) {
      // We assume that the error is due to the recipe not being found
    }
    const product = await this.productRepository.findByIdOrThrowError(productId);
    const recipe = createEmptyRecipeFromProduct(product);
    const id =  await this.recipeRepository.createById(productId,recipe); 
    recipe.id = id;
    return recipe 
  }
}
