import { BakingPlan } from "lib-shared-code";

import { getBakingPlanRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";
import { BakingPlanRepository } from "../data/repositories";
import { BakingPlanBusinessLogic } from "./app-business-logic";

export default class BakingPlanBusinessLogicImpl implements BakingPlanBusinessLogic {
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new BakingPlanBusinessLogicImpl(
      getBakingPlanRepository(db, bakeryIdGetter)
    );
  }

  constructor(private readonly bakingPlanRepository: BakingPlanRepository) {
    if (bakingPlanRepository === null) {
      throw new Error("bakingPlanRepository is null");
    }
  }

  getBakingPlans = async (): Promise<BakingPlan[]> => {
    return this.bakingPlanRepository.findAll();
  };

  addBakingPlan = async (bakingPlan: Omit<BakingPlan, "id">): Promise<string> => {
    return this.bakingPlanRepository.create(bakingPlan);
  };

  updateBakingPlan = async (
    id: string,
    bakingPlan: Partial<BakingPlan>
  ): Promise<void> => {
    return this.bakingPlanRepository.update(id, bakingPlan);
  };

  deleteBakingPlan = async (id: string): Promise<void> => {
    return this.bakingPlanRepository.deleteById(id);
  };

  getBakingPlan = async (id: string): Promise<BakingPlan | null> => {
    return this.bakingPlanRepository.findById(id);
  };
}
