import { Feedback, FeedbackLocation, Order } from "lib-shared-code";
import { FeedbackRepository, OrderRepository } from "src/app/data/repositories";

import {
  getFeedbackRepository,
  getOrderRepository,
} from "src/app/lib/firebase/firebase-repositories";
import { Firestore , deleteField} from "firebase/firestore";

import { FeedbackBusinessLogic } from "./app-business-logic";

export default class FeedbackBusinessLogicImpl
  implements FeedbackBusinessLogic
{
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new FeedbackBusinessLogicImpl(
      getFeedbackRepository(db, bakeryIdGetter),
      getOrderRepository(db, bakeryIdGetter)
    );
  }

  constructor(
    private readonly feedbackRepository: FeedbackRepository,
    private readonly orderRepository: OrderRepository
  ) {
    if (feedbackRepository === null) {
      throw new Error("taskRepository is null");
    }
  }

  getFeedbacks = async (): Promise<Feedback[]> => {
    return this.feedbackRepository.findAll();
  };

  addFeedback = async (task: Omit<Feedback, "id">): Promise<string> => {
    const id = await this.feedbackRepository.create(task);
    if (task.orderId) {
      const orderUpdate: Partial<Order> = {
        feedbackId: id,
        feedbackRating: task.rating,
      };
      await this.orderRepository.update(task.orderId, orderUpdate);
    }
    return id;
  };

  updateFeedback = async (
    id: string,
    task: Partial<Feedback>
  ): Promise<void> => {
    if (task.orderId) {
      const orderUpdate: Partial<Order> = {
        feedbackId: id,
        feedbackRating: task.rating,
      };
      await this.orderRepository.update(task.orderId, orderUpdate);
    }
    return this.feedbackRepository.update(id, task);
  };

  deleteFeedback = async (id: string): Promise<void> => {
    const task = await this.feedbackRepository.findByIdOrThrowError(id);
    if (task.orderId) {
      const orderUpdate: Partial<Order> = {
        feedbackId: "",
        feedbackRating: 0,
      };
      await this.orderRepository.update(task.orderId, orderUpdate);
    }
    return await this.feedbackRepository.deleteById(id);
  };

  getFeedback = async (id: string): Promise<Feedback | null> => {
    return this.feedbackRepository.findById(id);
  };
}
