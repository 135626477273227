import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { PartialConverter } from "../types";
import { Inventory } from "lib-shared-code";
import dayjs from "dayjs";
 

export const inventoryConverter: FirestoreDataConverter<Inventory> &
  PartialConverter<Inventory> = {
  toFirestorePartial,
  toFirestore,
  fromFirestore,
};


function toFirestorePartial(item: Partial<Inventory>): DocumentData {
  const updateData: DocumentData = { ...item };
  if (item.lastUpdated) {
    updateData.lastUpdated = Timestamp.fromDate(item.lastUpdated.toDate());
  }
  if (item.lastReconciliation) {
    updateData.lastReconciliation = Timestamp.fromDate(
      item.lastReconciliation.toDate()
    );
  }

  return updateData;
}
function toFirestore(item: Inventory): DocumentData {
  const partialDocumentData = toFirestorePartial(item);
  const firestoreData: DocumentData = { ...item, ...partialDocumentData };
  return firestoreData;
}

function fromFirestore(snapshot: QueryDocumentSnapshot): Inventory {
  const data = snapshot.data();
  return {
    id: snapshot.id,
    ...data,
    lastUpdated: data.lastUpdated ? dayjs(data.lastUpdated.toDate()) : dayjs(),
    lastReconciliation: data.lastReconciliation
      ? dayjs(data.lastReconciliation.toDate())
      : dayjs(),
  } as Inventory;
}
