import { FirebaseApp } from "firebase/app";
import { ReactNode, FC } from "react";
import { AuthProvider as FirebaseAuthProvider } from "src/app/auth/context/firebase";
import { AuthProvider as OfflineAuthProvider } from "src/app/auth/context/firebase/auth-offilne-provider";
// import { AuthProvider as FirebaseAuthProvider } from "src/app/auth/context/firebase";
// import { AuthProvider as OfflineAuthProvider } from "src/app/auth/context/firebase/auth-offline-provider";

export default interface AuthProviderFactory {
  create: () => FC<{ children: ReactNode }>;
}

export class OfflineAuthProviderFactory implements AuthProviderFactory {
  create() {
    return ({ children }: { children: ReactNode }) => (
      <OfflineAuthProvider>{children}</OfflineAuthProvider>
    );
  }
}

export class FirebaseAuthProviderFactory implements AuthProviderFactory {
  constructor(
    private firebaseApp: FirebaseApp,
    private onUserClaimsChanged: (claims: {
      userRole: string;
      userBakeryId: string;
    }) => void
  ) {}

  create() {
    const firebaseApp = this.firebaseApp; // Capture firebaseApp in closure
    return ({ children }: { children: ReactNode }) => (
      <FirebaseAuthProvider
        firebaseApp={firebaseApp}
        onUserClaimsChanged={this.onUserClaimsChanged}
      >
        {children}
      </FirebaseAuthProvider>
    );
  }
}
