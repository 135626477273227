import { useHelmet } from "src/app/lib/helmet/lib-helmet";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  CustomBreadcrumbs,
  Iconify,
  useSettingsContext,
} from "lib-mui-template";
import { paths } from "src/app/navigation/paths";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import ProductsList from "./ProductsList";

// ----------------------------------------------------------------------


export type ICaseStudyProps = {
  id: string;
  title: string;
  description: string;
  category: string;
  coverImg: string;
  // heroImg: string;
  createdAt: Date | string | number;
  // galleryImgs: string[];
  website: string;
};

const caseStudies: ICaseStudyProps[] = [
  {
    id: "1",
    title: "Oat and carob bread",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Breads",
    coverImg: "https://saborysaludable.com/es/images/060a7b92052e1c7f8823516f0c5cfa10.jpg",
    // heroImg: "",
    createdAt: "2021-06-12T09:00:00.000Z",
    // galleryImgs: [ ],
    website: "https://www.google.com",
  },
  {
    id: "2",
    title: "Sweet onion bread",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Breads",
    coverImg: "https://saborysaludable.com/en/images/5ca745104dd7ae114f0282e6207a8d83.jpg",
    // heroImg: "/static/images/products/product_2_hero.jpg",
    createdAt: "2021-06-12T09:00:00.000Z",
    // galleryImgs: [ ],
    website: "https://www.google.com",
  },
  {
    id: "3",
    title: "Multigrain bread",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Breads",
    coverImg: "https://saborysaludable.com/en/images/dd25201b4fab157bd3b8d851e77d0de8.jpg",
    // heroImg: "/static/images/products/product_3_hero.jpg",
    createdAt: "2021-06-12T09:00:00.000Z",
    // galleryImgs: [ ],
    website: "https://www.google.com",
  },
  {
    id: "4",
    title: "Oat bread",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Breads",
    coverImg: "https://saborysaludable.com/en/images/4894875436d584563d01c9110a93def4.jpg",
    // heroImg: "/static/images/products/product_3_hero.jpg",
    createdAt: "2021-06-12T09:00:00.000Z",
    // galleryImgs: [ ],
    website: "https://www.google.com",
  },
  
  {
    id: "11",
    title: "Lemon cake",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Cakes",
    coverImg: "https://saborysaludable.com/en/images/e8e4fc7031b6b75e9e1a842078231374.jpg",
    // heroImg: "",
    createdAt: "2021-06-12T09:00:00.000Z",
    // galleryImgs: [ ],
    website: "https://www.google.com",
  },
  {
    id: "12",
    title: "Chocolate Raspberry Cake",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Cakes",
    coverImg: "https://saborysaludable.com/en/images/f6f280a8644f048e40b912f75c86346b.jpg",
    // heroImg: "/static/images/products/product_2_hero.jpg",
    createdAt: "2021-06-12T09:00:00.000Z",
    // galleryImgs: [ ],
    website: "https://www.google.com",
  },
  {
    id: "13",
    title: "Creamy Fruit Cake",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Cakes",
    coverImg: "https://saborysaludable.com/en/images/34b99374c2530cc4a19415914d50eb39.jpg",
    // heroImg: "/static/images/products/product_3_hero.jpg",
    createdAt: "2021-06-12T09:00:00.000Z",
    // galleryImgs: [ ],
    website: "https://www.google.com",
  },
  
  
]

//  gluten, dairy, eggs, soy, palm oil, refined sugar
export default function BreadsPage() {
  const settings = useSettingsContext();
  const title = "Breads";
  const Helmet = useHelmet({ title });
  return (
    <>
    <Container>
      <Stack
        spacing={3}
        sx={{
          py: 5,
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography variant="h2">Our Products</Typography>

        {/* <Typography sx={{ color: 'text.secondary' }}>
        WE SAY NO TO ALLERGENS,
          <br />  NOT TO FLAVOR!
        </Typography> */}
      </Stack>

      <ProductsList caseStudies={caseStudies} />
    </Container>

    {/* <TestimonialMarketing testimonials={_testimonials} />

    <BlogMarketingLatestPosts posts={_blogMarketingPosts.slice(0, 4)} />

    <MarketingLandingFreeSEO />

    <NewsletterMarketing /> */}
  </>
  );
}
