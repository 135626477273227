import dayjs from "dayjs";
import { Order, OrderStatus } from "lib-shared-code";

export function isOrderStatusChanged(order: Order, nextStatus?: OrderStatus): boolean {
  return order.orderStatus !== order.previouslyUpdatedOrderStatus 
  || (nextStatus ? order.orderStatus !== nextStatus : false);
}

// export function updateOrderIfStatusChanged(order: Order) {
//   if (isOrderStatusChanged(order)) {
//     if (order.orderStatus === OrderStatus.Delivered) {
//       updateOrderOnDeliveryStatusChanged(order);
//     }
//   }
// }

// export function updateOrderOnDeliveryStatusChanged(order: Order) {
//   if (!order.deliveryDate) {
//     order.deliveryDate = dayjs();
//   }
// }
