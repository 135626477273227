import Button from '@mui/material/Button';
import { Theme, SxProps } from '@mui/material/styles';

import { RouterLink } from "lib-mui-template";
import { paths } from 'src/app/navigation/paths';
// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function LoginButton({ sx }: Props) {
  return (
    <Button component={RouterLink} href={paths.auth.firebase.login} variant="outlined" sx={{ mr: 1, ...sx }}>
      Login
    </Button>
  );
}
