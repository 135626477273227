import React from "react";

import PlaceHolderView from "../sections/placeholder/PlaceHolderView";
import { useHelmet } from "../lib/helmet/lib-helmet";

const PlaceHolderPage: React.FC = () => {
  const Helmet = useHelmet({ title: "PlaceHolderPage Title" });
  return (
    <div>
      <Helmet />
      <PlaceHolderView />
    </div>
  );
};

export default PlaceHolderPage;
