import { Ingredient } from "lib-shared-code";
import { IngredientRepository } from "src/app/data/repositories";
import { IngredientBusinessLogic } from "./app-business-logic";
import { getIngredientRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";

export default class IngredientBusinessLogicImpl implements IngredientBusinessLogic {
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new IngredientBusinessLogicImpl(
      getIngredientRepository(db, bakeryIdGetter)
    );
  }

  constructor(private readonly ingredientRepository: IngredientRepository) {
    if (ingredientRepository === null) {
      throw new Error("ingredientRepository is null");
    }
  }

  getIngredients = async (): Promise<Ingredient[]> => {
    return this.ingredientRepository.findAll();
  };

  addIngredient = async (ingredient: Omit<Ingredient, "id">): Promise<string> => {
    return this.ingredientRepository.create(ingredient);
  };

  updateIngredient = async (
    id: string,
    ingredient: Partial<Ingredient>
  ): Promise<void> => {
    return this.ingredientRepository.update(id, ingredient);
  };

  deleteIngredient = async (id: string): Promise<void> => {
    return this.ingredientRepository.deleteById(id);
  };

  getIngredient = async (id: string): Promise<Ingredient | null> => {
    return this.ingredientRepository.findById(id);
  };
}
