// @mui
import { alpha, styled } from "@mui/material/styles";
import {
  Stack,
  Container,
  Typography,
  Button,
  Fab,
  Unstable_Grid2 as Grid,
} from "@mui/material";
// utils
import { bgGradient } from "lib-mui-template";
// hooks
import { useResponsive } from "lib-mui-template";
// components
import { Image } from "lib-mui-template";
import { Iconify } from "lib-mui-template";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, 0.9),
    imgUrl: "/assets/background/overlay_1.jpg",
  }),
  overflow: "hidden",
}));

// ----------------------------------------------------------------------

export default function HomeHero() {
  const isMdUp = useResponsive("up", "md");

  return (
    <StyledRoot>
      <Container
        sx={{
          py: 15,
          display: { md: "flex" },
          alignItems: { md: "center" },
          height: { md: `100vh` },
        }}
      >
        <Grid container columnSpacing={{ xs: 0, md: 10 }}>
          <Grid
            xs={12}
            md={6}
            lg={5}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography variant="overline" sx={{ color: "secondary.main" }}>
              Streamline Your Baking Business
            </Typography>

            <Typography variant="h1" sx={{ my: 3 }}>
              Home Bakery Suite
            </Typography>

            <Typography sx={{ color: "text.secondary" }}>
              With our app, effortlessly manage orders, clients, recipes, and
              deliveries—all in one seamless interface. Simplify your day-to-day
              tasks, focus on baking, and grow your business with features
              tailored to your needs
            </Typography>

            <Stack
              spacing={3}
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "center", md: "unset" }}
              justifyContent={{ xs: "center", md: "unset" }}
              sx={{ mt: 5 }}
            >
              <Button variant="contained" color="inherit" size="large">
                Try For Free
              </Button>

              <Stack
                direction="row"
                alignItems="center"
                sx={{ typography: "h6" }}
              >
                <Fab size="medium" sx={{ mr: 1 }}>
                  <Iconify width={24} icon="carbon:play" />
                </Fab>
                Learn More
              </Stack>
            </Stack>
          </Grid>

          {isMdUp && (
            <Grid xs={12} md={6} lg={7}>
              <Image
                visibleByDefault
                disabledEffect
                alt="marketing market"
                src="/assets/illustrations/illustration_marketing_market.svg"
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledRoot>
  );
}
