// import ReactDOM from "react-dom/client";
// import { Footer } from "lib-ui-templates";
// import { Suspense } from "react";
// import { BrowserRouter } from "react-router-dom";
// import { HelmetProvider } from "react-helmet-async";
import App from "./app/app-main";
import { LibHelmetWebImpl } from "./web/lib/lib-helmet-web-impl";
import { firebaseConfig } from "./config";
import "./global.css";

console.info("PRODUCTION Web App v 0.0.1");
App.create(firebaseConfig).andWithHelmet(new LibHelmetWebImpl()).start();

// new App({ basename: "/index-web.html", firebaseConfig })
// .andWithHelmet(new LibHelmetWebImpl())
// .start();

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

// const Main = () => {
//   return (
//     <div>
//       <h1>Web Frontend Apps</h1>
//       <h2>React App2</h2>
//       <App></App>
//       <Footer />
//     </div>
//   );
// };

// root.render(
//   <HelmetProvider>
//     <BrowserRouter>
//       <Suspense>
//         <Main />
//       </Suspense>
//     </BrowserRouter>
//   </HelmetProvider>
// );
