import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useSettingsContext } from "lib-mui-template";

// ----------------------------------------------------------------------

export default function PlaceHolderView() {
  const settings = useSettingsContext();

  return (
    <Box
    sx={{
      height: { md: 560 },
      py: { xs: 10, md: 0 },
      overflow: 'hidden',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage:
        'url(/assets/background/overlay_1.svg), url(/assets/images/about/hero.jpg)',
    }}
  >
    
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Typography variant="h4">  Place Holder H4</Typography>

      <Box
        sx={{
          mt: 5,
          width: 1,
          height: 320,
          borderRadius: 2,
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
      >
        Place Holder View
      </Box>
    </Container></Box>
  );
}
