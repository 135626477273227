import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { Client, OrderSnapshot } from "lib-shared-code";
import dayjs from "dayjs";
import { PartialConverter } from "../types";

export const clientConverter: FirestoreDataConverter<Client> &
  PartialConverter<Client> = {
  toFirestorePartial,
  toFirestore,
  fromFirestore,
};

export function toFirestorePartial(model: Partial<Client>): DocumentData {
  const updateData: DocumentData = { ...model };
  if (model.insights && model.insights.firstInquiryDate) {
    updateData.insights.firstInquiryDate = Timestamp.fromDate(
      model.insights.firstInquiryDate.toDate()
    );
  }
  if (model.lastOrderDate) {
    updateData.lastOrderDate = Timestamp.fromDate(model.lastOrderDate.toDate());
  }
  if (model.firstOrderDate) {
    updateData.firstOrderDate = Timestamp.fromDate(model.firstOrderDate.toDate());
  }

  if (model.lastOrders) {
    updateData.lastOrders = toFirestoreLastOrder(model.lastOrders);
  }
 
  return updateData;
}

export function toFirestore(client: Client): DocumentData {
  // Convert Date objects to Firestore Timestamps and handle enums
  const partialDocumentData = toFirestorePartial(client);
  const firestoreData = {
    ...partialDocumentData,
    lastOrders: toFirestoreLastOrder(client.lastOrders || []),
  };
  return firestoreData;
}

export function fromFirestore(snapshot: QueryDocumentSnapshot): Client {
  const data = snapshot.data();
  const result = {
    id: snapshot.id,
    ...data,
    lastOrders: fromFirestoreLastOrder(data.lastOrders || []),
    lastOrderDate: data.lastOrderDate
      ? dayjs(data.lastOrderDate.toDate())
      : null,
    firstOrderDate: data.firstOrderDate
      ? dayjs(data.firstOrderDate.toDate())
      : null,
    insights: data.insights
      ? {
          ...data.insights,
          firstInquiryDate: dayjs(
            (data.insights.firstInquiryDate as Timestamp).toDate()
          ),
        }
      : {},
  } as Client;
  return result;
}

const toFirestoreLastOrder = (items: OrderSnapshot[]): DocumentData[] => {
  const result = items.map((item) => {
    const data = {
      ...item,
      orderDate: item.orderDate
        ? Timestamp.fromDate(item.orderDate.toDate())
        : null,
    };
    return data;
  });

  return result;
};

const fromFirestoreLastOrder = (items: DocumentData[]): OrderSnapshot[] => {
  const result = items.map((item) => {
    const data = {
      ...item,
      orderDate: item.orderDate ? dayjs(item.orderDate.toDate()) : null,
    };
    return data as OrderSnapshot;
  });

  return result;
};

/*
improve above code by using generics 
// Generic function to convert dates to Firestore Timestamps
function convertDatesToTimestamp<T>(items: T[], dateFields: (keyof T)[]): DocumentData[] {
  return items.map((item) => {
    const data = { ...item };
    dateFields.forEach((field) => {
      if (data[field]) {
        data[field] = Timestamp.fromDate(data[field].toDate());
      }
    });
    return data;
  });
}

// Generic function to convert Firestore Timestamps to another date format
function convertTimestampsToDate<T, R>(items: T[], dateFields: (keyof T)[], convertFunction: (date: Date) => any): R[] {
  return items.map((item) => {
    const data = { ...item };
    dateFields.forEach((field) => {
      if (data[field]) {
        data[field] = convertFunction(data[field].toDate());
      }
    });
    return data as R;
  });
}

// Usage for OrderSnapshot and ClientFeedback conversions
const toFirestoreLastOrder = (items: OrderSnapshot[]): DocumentData[] => 
  convertDatesToTimestamp(items, ['orderDate']);

const fromFirestoreLastOrder = (items: DocumentData[]): OrderSnapshot[] => 
  convertTimestampsToDate(items, ['orderDate'], dayjs);

const toFirestoreFeedback = (items: ClientFeedback[]): DocumentData[] => 
  convertDatesToTimestamp(items, ['date']);

const fromFirestoreFeedback = (items: DocumentData[]): ClientFeedback[] => 
  convertTimestampsToDate(items, ['date'], dayjs);

  */