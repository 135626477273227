import { FirebaseApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import ClientBusinessLogicImpl from "./business-logic/client-business-logic";
import OrderBusinessLogicImpl from "./business-logic/order-business-logic";
import ProductBusinessLogicImpl from "./business-logic/product-business-logic";
import TaskBusinessLogicImpl from "./business-logic/task-business-logic";
import FeedbackBusinessLogicImpl from "./business-logic/feedback-business-logic";
import IngredientBusinessLogicImpl from "./business-logic/ingredient-business-logic";
import RecipeBusinessLogicImpl from "./business-logic/recipe-business-logic";
import InventoryBusinessLogicImpl from "./business-logic/inventory-business-logic";
import BakingPlanBusinessLogicImpl from "./business-logic/baking-plan-business-logic";
import ProductDisplayBusinessLogicImpl from "./business-logic/product-display-business-logic";

export function createBusinessLogicRegistry(
  app: FirebaseApp,
  firestoreCreatedCallback?: (firestore: Firestore) => void,
  bakeryIdGetter?: () => string
) {
  if (!bakeryIdGetter) {
    throw new Error("bakeryIdGetter is not defined");
  }
  const db = getFirestore(app);
  if (firestoreCreatedCallback) firestoreCreatedCallback(db);
  const orderBL = OrderBusinessLogicImpl.create(db, bakeryIdGetter);
  const clientBL = ClientBusinessLogicImpl.create(db, bakeryIdGetter);
  const productBL = ProductBusinessLogicImpl.create(db, bakeryIdGetter);
  const taskBL = TaskBusinessLogicImpl.create(db, bakeryIdGetter);
  const feedbackBL = FeedbackBusinessLogicImpl.create(db, bakeryIdGetter);
  const ingredientBL = IngredientBusinessLogicImpl.create(db, bakeryIdGetter);
  const recipeBL = RecipeBusinessLogicImpl.create(db, bakeryIdGetter);
  const productDisplayBL = ProductDisplayBusinessLogicImpl.create(db, bakeryIdGetter);
  const inventoryBl = InventoryBusinessLogicImpl.create(db, bakeryIdGetter);
  const bakingPlanBl = BakingPlanBusinessLogicImpl.create(db, bakeryIdGetter);

  return {
    clientBL,
    orderBL,
    productBL,
    taskBL,
    feedbackBL,
    ingredientBL,
    recipeBL,
    productDisplayBL,
    inventoryBl,
    bakingPlanBl,
  };
}

export type BusinessLogic = ReturnType<typeof createBusinessLogicRegistry>;
