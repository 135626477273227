import * as yup from "yup";
import dayjs from "dayjs";

// Extend the yup interface to include the new method signature
declare module "yup" {
  interface MixedSchema {
    dayjs(options?: { min?: string | Date; max?: string | Date }): MixedSchema;
  }
}

// Extend the yup mixed schema to include dayjs validation with min and max date options
yup.addMethod(
  yup.mixed,
  "dayjs",
  function (options?: { min?: string | Date; max?: string | Date }) {
    return this.test("dayjs", "Invalid date", function (value) {
      const { path, createError } = this;

      if (options?.min || options?.max) {
        return createError({
          path,
          message: "Options Min or Max not implemented.",
        });
      }
      // Check for required validity first
      if (value === undefined || value === null || value === "") return true;

      // Validate if the value is a valid dayjs object
      if (!dayjs.isDayjs(value)) {
        return createError({ path, message: "Invalid date format" });
      }

      // TODO: implement min and max options
      // https://day.js.org/docs/en/plugin/custom-parse-format
      // https://day.js.org/docs/en/parse/string-format
      // Validate minimum date
      //   if (options?.min && dayjs(value).isBefore(dayjs(options.min))) {
      //     return createError({
      //       path,
      //       message: `Date must be on or after ${dayjs(options.min).format(
      //         "YYYY-MM-DD"
      //       )}`,
      //     });
      //   }
      //
      // Validate maximum date
      //   if (options?.max && dayjs(value).isAfter(dayjs(options.max))) {
      //     return createError({
      //       path,
      //       message: `Date must be on or before ${dayjs(options.max).format(
      //         "YYYY-MM-DD"
      //       )}`,
      //     });
      //   }

      // If all validations pass
      return true;
    });
  }
);
