import { Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import { SplashScreen } from 'src/app/components/processing';

import { GuestGuard } from "src/app/auth/guard";
import AuthClassicLayout from "src/app/layouts/auth/classic";
import CompactLayout from "src/app/layouts/compact";

const FirebaseLoginPage = lazy(
  () => import("src/app/pages/auth/firebase/login")
);
const FirebaseRegisterPage = lazy(
  () => import("src/app/pages/auth/firebase/register")
);
const FirebaseVerifyPage = lazy(
  () => import("src/app/pages/auth/firebase/verify")
);
const FirebaseForgotPasswordPage = lazy(
  () => import("src/app/pages/auth/firebase/forgot-password")
);

const authFirebase = {
  path: "firebase",
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: "login",
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <FirebaseLoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      path: "register",
      element: (
        <GuestGuard>
          <AuthClassicLayout title="Manage the job more effectively with Home Bakary Suit">
            <FirebaseRegisterPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: "verify", element: <FirebaseVerifyPage /> },
        { path: "forgot-password", element: <FirebaseForgotPasswordPage /> },
      ],
    },
  ],
};
export const authRoutes = [
  {
    path: "auth",
    children: [authFirebase],
  },
];


