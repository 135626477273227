import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { formHelperTextClasses } from "@mui/material/FormHelperText";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Rating from "@mui/material/Rating";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  FormProvider,
  Iconify,
  Label,
  LabelColor,
  RHFSelect,
  useRouter,
  varFade,
} from "lib-mui-template";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { fCurrency, fShortenNumber } from "src/app/data/formatters";
import { paths } from "src/app/navigation/paths";
import { IProductItem } from "./BreadPage";
import { m } from 'framer-motion';
// import { paths } from 'src/routes/paths';
// import { useRouter } from 'src/routes/hooks';

// import { fCurrency, fShortenNumber } from 'src/utils/format-number';

// import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
// import { ColorPicker } from 'src/components/color-utils';
// import FormProvider, { RHFSelect } from 'src/components/hook-form';

// import { IProductItem } from 'src/types/product';
// import { ICheckoutItem } from 'src/types/checkout';

// import IncrementerButton from './common/incrementer-button';

// ----------------------------------------------------------------------
export type ICheckoutItem = {
  id: string;
  name: string;
  coverUrl: string;
  available: number;
  price: number;
  colors: string[];
  size: string;
  quantity: number;
  subTotal: number;
};

type Props = {
  product: IProductItem;
  items?: ICheckoutItem[];
  disabledActions?: boolean;
  onGotoStep?: (step: number) => void;
  onAddCart?: (cartItem: ICheckoutItem) => void;
};

export default function ProductDetailsSummary({
  items,
  product,
  onAddCart,
  onGotoStep,
  disabledActions,
  ...other
}: Props) {
  const router = useRouter();

  const {
    id,
    name,
    sizes,
    price,
    coverUrl,
    colors,
    newLabel,
    available,
    priceSale,
    saleLabel,
    totalRatings,
    totalReviews,
    inventoryType,
    subDescription,
    ingredients,
    freeFrom,
    variants,
    labels,
  } = product;

  const existProduct =
    !!items?.length && items.map((item) => item.id).includes(id);

  const isMaxQuantity =
    !!items?.length &&
    items.filter((item) => item.id === id).map((item) => item.quantity)[0] >=
      available;

  const defaultValues = {
    id,
    name,
    coverUrl,
    available,
    price,
    colors: colors[0],
    size: sizes[4],
    quantity: available < 1 ? 0 : 1,
  };

  const methods = useForm({
    defaultValues,
  });

  const { reset, watch, control, setValue, handleSubmit } = methods;

  const values = watch();

  useEffect(() => {
    if (product) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!existProduct) {
        onAddCart?.({
          ...data,
          colors: [values.colors],
          subTotal: data.price * data.quantity,
        });
      }
      onGotoStep?.(0);
      router.push(paths.dashboard.products.root); // checkout
    } catch (error) {
      console.error(error);
    }
  });

  const handleAddCart = useCallback(() => {
    try {
      onAddCart?.({
        ...values,
        colors: [values.colors],
        subTotal: values.price * values.quantity,
      });
    } catch (error) {
      console.error(error);
    }
  }, [onAddCart, values]);

  const renderPrice = (
    <Box sx={{ typography: "h5" }}>
      {priceSale && (
        <Box
          component="span"
          sx={{
            color: "text.disabled",
            textDecoration: "line-through",
            mr: 0.5,
          }}
        >
          {fCurrency(priceSale)}
        </Box>
      )}

      {fCurrency(price)}
    </Box>
  );

  const renderShare = (
    <Stack direction="row" spacing={3} justifyContent="center">
      {freeFrom.map(({ label, icon }) => (
        <Link
          key={label}
          variant="subtitle2"
          sx={{
            color: "text.secondary",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          <Iconify icon={icon} width={69} sx={{ mr: 1 }} />
          Gluten-free
        </Link>
      ))}
    </Stack>
  );

  const renderColorOptions = (
    <Stack direction="row">
      <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
        Color
      </Typography>

      {/* <Controller
        name="colors"
        control={control}
        render={({ field }) => (
          <ColorPicker
            colors={colors}
            selected={field.value}
            onSelectColor={(color) => field.onChange(color as string)}
            limit={4}
          />
        )}
      /> */}
    </Stack>
  );

  const renderSizeOptions = (
    <Stack direction="row">
      <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
        Size
      </Typography>

      <RHFSelect
        name="size"
        size="small"
        helperText={
          <Link underline="always" color="textPrimary">
            Size Chart
          </Link>
        }
        sx={{
          maxWidth: 88,
          [`& .${formHelperTextClasses.root}`]: {
            mx: 0,
            mt: 1,
            textAlign: "right",
          },
        }}
      >
        {sizes.map((size) => (
          <MenuItem key={size} value={size}>
            {size}
          </MenuItem>
        ))}
      </RHFSelect>
    </Stack>
  );

  const renderQuantity = (
    <Stack direction="row">
      <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
        Quantity
      </Typography>

      <Stack spacing={1}>
        {/* <IncrementerButton
          name="quantity"
          quantity={values.quantity}
          disabledDecrease={values.quantity <= 1}
          disabledIncrease={values.quantity >= available}
          onIncrease={() => setValue('quantity', values.quantity + 1)}
          onDecrease={() => setValue('quantity', values.quantity - 1)}
        /> */}

        <Typography
          variant="caption"
          component="div"
          sx={{ textAlign: "right" }}
        >
          Available: {available}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderActions = (
    <Stack direction="row" spacing={2}>
      <Button
        fullWidth
        disabled={isMaxQuantity || disabledActions}
        size="large"
        color="warning"
        variant="contained"
        startIcon={<Iconify icon="solar:cart-plus-bold" width={24} />}
        onClick={handleAddCart}
        sx={{ whiteSpace: "nowrap" }}
      >
        Add to Cart
      </Button>

      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={disabledActions}
      >
        Buy Now
      </Button>
    </Stack>
  );

  const renderSubDescription = (
    <Typography variant="body2" sx={{ color: "text.secondary" }}>
      {subDescription}
    </Typography>
  );
  const renderIngredients = (
    <>
      <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
        Ingredients
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {ingredients}
      </Typography>
    </>
  );

  const renderRating = (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        color: "text.disabled",
        typography: "body2",
      }}
    >
      <Rating
        size="small"
        value={totalRatings}
        precision={0.1}
        readOnly
        sx={{ mr: 1 }}
      />
      {`(${fShortenNumber(totalReviews)} reviews)`}
    </Stack>
  );

  const renderLabels1 = (newLabel.enabled || saleLabel.enabled) && (
    <Stack direction="row" alignItems="center" spacing={1}>
      {newLabel.enabled && <Label color="info">{newLabel.content}</Label>}
      {saleLabel.enabled && <Label color="error">{saleLabel.content}</Label>}
    </Stack>
  );
  const renderLabels = (
    <Stack direction="row" alignItems="center" spacing={1}>
      {labels.map(({ label, color, tooltip }) => (
        <Tooltip key={label} title={tooltip} arrow>
          <Label variant="filled" key={label} color={color as LabelColor}>
            {label}
          </Label>
        </Tooltip>
      ))}
    </Stack>
  );

  const renderInventoryType = (
    <Box
      component="span"
      sx={{
        typography: "overline",
        color:
          (inventoryType === "out of stock" && "error.main") ||
          (inventoryType === "low stock" && "warning.main") ||
          "success.main",
      }}
    >
      {inventoryType}
    </Box>
  );

  const renderVariants_old = (
    <>
      <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
        Sizes
      </Typography>
      <Stack spacing={1} sx={{ p: 0, m: 0, typography: "body2" }}>
        {variants.map((variant) => (
          <Stack direction="row" alignItems="center">
            <Box
              component="span"
              sx={{ color: "text.secondary", width: 120, flexShrink: 0 }}
            >
              {variant.name}
            </Box>
            {fCurrency(variant.price)}
          </Stack>
        ))}
      </Stack>
    </>
  );


  const renderVariants = (
    <>
  
<Stack direction="row" spacing={2} sx={{ p: 0, m: 0, typography: "body2", overflowX: 'auto' }}>
<Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
  Sizes:
</Typography>
  {variants.map((variant, index) => (
    <Stack sx={{px:1}} key={index} direction="row" alignItems="center" spacing={1}>
      <Box
        component="span"
        sx={{ color: "text.secondary", minWidth: 50, flexShrink: 0 }}
      >
        {variant.name}:
      </Box>
      {fCurrency(variant.price)}
    </Stack>
  ))}
</Stack>

    </>
  );

  const renderFreeFromOld = (
    <>
      <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
        Free From:
      </Typography>
      <Box
        gap={1}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(3, 1fr)",
          md: "repeat(6, 1fr)",
        }}
        sx={{ my: 0 }}
      >
        {freeFrom.map((item) => (
          <Box key={item.label} sx={{ textAlign: "center", px: 0 }}>
            <Iconify
              icon={item.icon}
              width={30}
              sx={{ color: "primary.main" }}
            />

            {/* <Typography variant="subtitle1" sx={{ mb: 1, mt: 2 }}>
              {item.label}
            </Typography> */}

            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );

  const renderFreeFrom2 = (
    <Grid xs={12} md={12}>
    <Box
     gridTemplateColumns={{
      xs: `repeat(4, 1fr)`,
      sm: `repeat(5, 1fr)`,
      md: `repeat(4, 1fr)`,
    }}
      sx={{
        rowGap: 6,
        columnGap: 3,
        display: 'grid',
      }}
    >
      {freeFrom.map((feature) => (
        <m.div key={feature.label} variants={varFade({ distance: 40 }).in}>
          <Box sx={{ textAlign: 'center' }}>
            <Iconify icon={feature.icon} width={28}
            sx={{ color: "primary.main", mb: 1 }} />

            <Typography variant="caption" component="div" sx={{ mt: 0 }}>
              {feature.label}
            </Typography>
          </Box>
        </m.div>
      ))}
    </Box>
  </Grid>
  )

  const renderFreeFrom = (
    <>
      <Box
    gap={2}
    display="grid"
    gridTemplateColumns={{
      xs: `repeat(4, 1fr)`,
      sm: `repeat(5, 1fr)`,
      md: `repeat(4, 1fr)`,
    }}
    sx={{ my: 2 }}
  >
    {freeFrom.map((item) => (
      <Box
        key={item.label}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1,
          // width: '80px',
          border: '0px solid',
          borderColor: 'grey.300',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <Iconify
          icon={item.icon}
          width={28}
          height={28}
          sx={{ color: "primary.main", mb: 1 }}
        />
        <Typography variant="caption">
          {item.label}
        </Typography>
      </Box>
    ))}
  </Box>
    </>
  );
  
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ pt: 3 }} {...other}>
        <Stack spacing={2} alignItems="flex-start">
          {/* {renderInventoryType} */}

          <Typography variant="h4">{name}</Typography>
          {renderLabels}
          {renderSubDescription}

          {renderVariants}
          {/* {renderRating} */}

          {/* {renderPrice} */}
         
        </Stack>  
        {/* <Divider sx={{ borderStyle: "dashed" }} /> */}
        {/* {renderVariants} */}
          {renderIngredients}
          {/* {renderFreeFrom} */}
          {renderFreeFrom2}
        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        
        {/* {renderColorOptions} */}

        {/* {renderSizeOptions} */}
       
        {/* {renderQuantity} */}
        
      

        {/* {renderActions} */}

        {/* {renderShare} */}
      </Stack>
    </FormProvider>
  );
}
