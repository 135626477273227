import { useFormContext, Controller } from "react-hook-form";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker"; // Adjust this import based on your actual DatePicker component path

// Extend DatePickerProps with the necessary props for the form field
type RHFDatePickerProps<TDate> = {
  name: string;
} & DatePickerProps<TDate>;

export default function RHFDatePicker<TDate>({
  name,
  ...other
}: RHFDatePickerProps<TDate>) {
  const { control } = useFormContext(); // Use the form context

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          value={field.value || null}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
            },
          }}
          {...other}
        />
      )}
    />
  );
}
