import { Order, OrderItem, OrderStatus } from "lib-shared-code";
import { genericOperationsFactory } from "../generic-operations-factory";
import { FirebaseApp } from "firebase/app";
import {
  FirestoreDataConverter,
  Timestamp,
  DocumentData,
  QueryDocumentSnapshot,
  getFirestore,
  collection,
  doc,
  getDoc,
  FieldValue,
  deleteField,
} from "firebase/firestore";
import dayjs from "dayjs";
import { PartialConverter } from "../types";

const toFirestorePartial = (model: Partial<Order>): DocumentData => {
  const updateData: DocumentData = { ...model };
  if (model.orderStatus) {
    updateData.orderStatus = model.orderStatus.toString();
  }
  if (model.orderDate && dayjs.isDayjs(model.orderDate)) {
    updateData.orderDate = model.orderDate.toDate();
  }
  if (model.deliveryDate && dayjs.isDayjs(model.deliveryDate)) {
    updateData.deliveryDate = model.deliveryDate.toDate();
  }
  if (
    model.delivery &&
    model.delivery.deliveryTime &&
    dayjs.isDayjs(model.delivery.deliveryTime)
  ) {
    updateData.delivery.deliveryTime = model.delivery.deliveryTime.toDate();
  }
  if (model.items) updateData.items = toFirestoreOrderItems(model.items);
  if (model.feedbackId === "") {
    updateData.feedbackId = deleteField();
    updateData.feedbackRating = deleteField();
  }
  return updateData;
};

export const orderConverter: FirestoreDataConverter<Order> &
  PartialConverter<Order> = {
  toFirestorePartial,
  toFirestore(order: Order): DocumentData {
    // Handling Enums: Convert enum values to strings
    const orderStatusAsString = order.orderStatus.toString();

    // Prepare data for Firestore, converting Date objects to Timestamps
    // and handling serialization of nested objects if necessary
    let _orderDate = null;
    if (dayjs.isDayjs(order.orderDate)) {
      _orderDate = order.orderDate.toDate();
    }
    let _deliveryDate = null;
    if (dayjs.isDayjs(order.deliveryDate)) {
      _deliveryDate = order.deliveryDate.toDate();
    }

    const firestoreData = {
      ...order,
      orderStatus: orderStatusAsString,
      orderDate: _orderDate ? Timestamp.fromDate(_orderDate) : null,
      deliveryDate: _deliveryDate ? Timestamp.fromDate(_deliveryDate) : null,
      items: toFirestoreOrderItems(order.items), // Example of handling nested arrays
      // Optionally handle other transformations or compressions here
    };
    // console.log("orderConverter.toFirestore", { order, firestoreData });
    // Return the prepared data for Firestore
    return firestoreData;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Order {
    const data = snapshot.data();

    // Handling Enums: Convert strings back to enums
    const orderStatusAsEnum =
      OrderStatus[data.orderStatus as keyof typeof OrderStatus];

    // Reconstruct the Order object, converting Timestamps back to Date objects
    // and handling deserialization of nested objects if necessary
    const result = {
      id: snapshot.id,
      ...data,
      orderStatus: orderStatusAsEnum,
      orderDate: data.orderDate
        ? dayjs((data.orderDate as Timestamp).toDate())
        : null, // Default value example
      deliveryDate: data.deliveryDate
        ? dayjs((data.deliveryDate as Timestamp).toDate())
        : null, // Default value example
      // items: data.items ? convertOrderItems(data.items as OrderItem[]) : [], // Example of handling nested arrays
      // Optionally handle other transformations or decompressions here
    } as Order;
    if (result.delivery && data.delivery.deliveryTime) {
      result.delivery.deliveryTime = dayjs((data.delivery.deliveryTime as Timestamp).toDate())
    }
    // console.debug("orderConverter.fromFirestore", { data, result });
    return result;
  },
};

const toFirestoreOrderItems = (items: OrderItem[]): OrderItem[] => {
  const result = items.map((item) => {
    const data = { ...item };
    if ("product" in data) delete data.product;
    if ("variant" in data) delete data.variant;
    return data;
  });
  // console.debug("toFirestoreOrderItems", { items, result });
  // Implement any specific transformations for OrderItems here
  // For example, converting date fields or handling nested structures
  return result;
};
