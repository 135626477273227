import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { Feedback, FeedbackLocation } from "lib-shared-code";
import { PartialConverter } from "../types";
import dayjs from "dayjs";

const toFirestorePartial = (model: Partial<Feedback>): DocumentData => {
  const updateData: DocumentData = { ...model };
  if (model.date && dayjs.isDayjs(model.date)) {
    updateData.date = model.date.toDate();
  }
  if (model.location) {
    updateData.location = model.location.toString();
  }
 
  return updateData;
};

export const feedbackConverter: FirestoreDataConverter<Feedback> &
  PartialConverter<Feedback> = {
  toFirestorePartial,
  toFirestore(feedback: Feedback): DocumentData {
    const partialDocumentData = toFirestorePartial(feedback);
    const firestoreData = {
      ...partialDocumentData,
    };

    return firestoreData;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Feedback {
    const data = snapshot.data();
    const locationAsEnum = FeedbackLocation[data.location as keyof typeof FeedbackLocation];

    const result = {
        id: snapshot.id,
        ...data,
        location: locationAsEnum,
        date: data.date ? dayjs((data.date as Timestamp).toDate()) : null, // Default value example

    } as Feedback;
    return result;
 
  },
};
