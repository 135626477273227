import { Client } from "lib-shared-code";
import { ClientRepository } from "src/app/data/repositories";
import { ClientBusinessLogic } from "./app-business-logic";
import { getClientRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";

export default class ClientBusinessLogicImpl implements ClientBusinessLogic {
  static create(db: Firestore,  bakeryIdGetter: () => string) {
    return new ClientBusinessLogicImpl(getClientRepository(db,bakeryIdGetter));
  }

  constructor(private readonly clientRepository: ClientRepository) {
    if (clientRepository === null) {
      throw new Error("clientRepository is null");
    }
  }

  getClients = async (): Promise<Client[]> => {
    return this.clientRepository.findAll();
  };

  addClient = async (client: Omit<Client, "id">): Promise<string> => {
    return this.clientRepository.create(client);
  };

  updateClient = async (id: string, client: Partial<Client>): Promise<void> => {
    return this.clientRepository.update(id, client);
  };

  deleteClient = async (id: string): Promise<void> => {
    return this.clientRepository.deleteById(id);
  };

  getClient = async (id: string): Promise<Client | null> => {
    return this.clientRepository.findById(id);
  };

}
