// repositories/GenericCollection.ts
// import { DatabaseService } from '../services/DatabaseService';
import {
  Firestore,
  FirestoreDataConverter,
  OrderByDirection,
  QueryDocumentSnapshot,
  WithFieldValue,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { FindByQueryParams, PartialConverter } from "./types";

const defaultFindByQueryParams = {
  additionalQueries: [],
  sortBy: null,
  sortOrder: "desc" as OrderByDirection,
  pageNumber: 1,
  pageSize: 100,
};

export function genericOperationsFactory<T>(
  firestoreDb: Firestore,
  collectionName: string,
  converter: FirestoreDataConverter<T> & PartialConverter<T>,
  bakeryIdGetter: () => string
) {
  if (!bakeryIdGetter) throw new Error("bakeryIdGetter is required");

  const collectionRef = collection(firestoreDb, collectionName).withConverter(
    converter
  );

  const findAll = async (): Promise<T[]> => {
    const clientsQuery = query(
      collectionRef,
      where("bakeryId", "==", bakeryIdGetter())
    );
    const snapshot = await getDocs(clientsQuery);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as T);
  };

  const findById = async (id: string): Promise<T | null> => {
    try {
      const docRef = doc(collectionRef, id);
      const snapshot = await getDoc(docRef);
      return snapshot.exists() ? snapshot.data() : null;
    } catch (error) {
      throw new Error(
        `Error fetching document by id ${id} from ${collectionName}:`,
        { cause: error }
      );
    }
  };

  const createById = async (
    id: string,
    data: Omit<T, "id">
  ): Promise<string> => {
    const bakeryId = bakeryIdGetter();
    const _data = { ...data, bakeryId };
    const docRef = doc(collectionRef, id);
    try {
    await setDoc(docRef, _data as WithFieldValue<T>);
  } catch (error) {
    throw new Error(
      `Error createById document in ${collectionName}: ${error}`,
      { cause: error }
    );
  }
    return docRef.id;
  };

  const create = async (data: Omit<T, "id">): Promise<string> => {
    const _data = { ...data, bakeryId: bakeryIdGetter() };
    if ("id" in _data) delete _data.id;
    const docRef = await addDoc(collectionRef, _data as WithFieldValue<T>);
    return docRef.id;
  };

  const update = async (id: string, data: Partial<T>): Promise<void> => {
    const docRef = doc(collectionRef, id);
    const updatedData = converter.toFirestorePartial(data);

    await updateDoc(docRef, updatedData);
  };

  const deleteById = async (id: string): Promise<void> => {
    const docRef = doc(collectionRef, id);
    await deleteDoc(docRef);
  };

  const findByQuery = async (
    params: FindByQueryParams = {}
  ): Promise<{ data: T[]; lastVisible: QueryDocumentSnapshot<T> | null }> => {
    const { additionalQueries, sortBy, sortOrder, pageNumber, pageSize } = {
      ...defaultFindByQueryParams,
      ...params,
    };

    const bakeryQuery = where("bakeryId", "==", bakeryIdGetter());
    const finalQueries: any[] = [bakeryQuery];
    if (sortBy && sortOrder) {
      const orderQuery = orderBy(sortBy, sortOrder);
      finalQueries.push(orderQuery);
    }
    finalQueries.push(...additionalQueries);

    const startQuery =
      pageNumber > 1 ? startAfter(pageSize * (pageNumber - 1)) : null;
    const limitQuery = limit(pageSize);

    if (startQuery) finalQueries.push(startQuery);
    finalQueries.push(limitQuery);

    const q = query(collectionRef, ...finalQueries);
    const snapshot = await getDocs(q);

    const lastVisible = snapshot.docs[snapshot.docs.length - 1] || null;
    const data = snapshot.docs.map(
      (doc) => ({ id: doc.id, ...doc.data() }) as T
    );
    return { data, lastVisible };
  };

  return {
    findAll,
    findById,
    create,
    update,
    deleteById,
    findByQuery,
    createById,
  };
}

export type GenericOperations<T> = ReturnType<
  typeof genericOperationsFactory<T>
>;
