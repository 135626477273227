import {
  Client,
  ClientAddress,
  ClientContactInfo,
  ClientContactSnapshot,
  ClientEngagementLevel,
} from "lib-shared-code";

 
export function toClientSnapshot(client: Client): ClientContactSnapshot {
  return {
    name: client.name,
    contactInfo: client.contactInfo,
    address: client.deliveryAddress
      ? client.deliveryAddress
      : ({
          city: client.insights?.residencyPlace,
          addressDetails: "",
        } as ClientAddress),
    engagementLevel: client.engagementLevel || null,
    dietaryRestrictions: client.dietaryRestrictions || "",
  };
}

export function revalidateEngagementLevel(
  client: Client
): ClientEngagementLevel {
  if (!client.lastOrders || client.lastOrders.length <= 1) {
    if (!client.engagementLevel) return ClientEngagementLevel.New;
  }

  const orderCounts = client.lastOrders?.length || 0;

  switch (client.engagementLevel) {
    case ClientEngagementLevel.New:
      if (orderCounts >= 2) return ClientEngagementLevel.Client;
      break;
    case ClientEngagementLevel.Client:
      if (orderCounts >= 7) return ClientEngagementLevel.Regular;
      break;
    case ClientEngagementLevel.Regular:
      //TODO: count how many orders per month is done by client
      if (orderCounts >= 20) return ClientEngagementLevel.VIP;
      break;
  }
  return client.engagementLevel;
}
