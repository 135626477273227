import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import { Task, TaskStatus, TaskCategory } from "lib-shared-code";
import dayjs from "dayjs";
import { PartialConverter } from "../types";

const toFirestorePartial = (model: Partial<Task>): DocumentData => {
  const updateData: DocumentData = { ...model };
  return updateData;
};

export const taskConverter: FirestoreDataConverter<Task> &
  PartialConverter<Task> = {
  toFirestorePartial,
  toFirestore(action: Task): DocumentData {
    // Handling serialization of Task object to Firestore format
    const firestoreData = {
      ...action,
      // Convert Dayjs objects to Firestore Timestamps
      scheduledDate: action.scheduledDate
        ? Timestamp.fromDate(action.scheduledDate.toDate())
        : null,
      // Enums like TaskStatus can be directly stored as strings
      status: action.status.toString(),
      category: action.category.toString(),
      // Additional properties do not need conversion and can be spread directly
    };

    return firestoreData;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Task {
    const data = snapshot.data();
    const statusAsEnum = TaskStatus[data.status as keyof typeof TaskStatus];
    const categoryAsEnum = TaskCategory[data.category as keyof typeof TaskCategory];
    return {
      id: snapshot.id,
      ...data,
      // Convert Firestore Timestamps back to Dayjs objects
      scheduledDate: dayjs(data.scheduledDate.toDate()),
      // The status, being an enum, should align with the stored string values and does not require conversion
      status: statusAsEnum,
      category: categoryAsEnum,
      // Other properties are directly taken from the data without needing conversion
    } as Task;
  },
};
