import Box from '@mui/material/Box';

import { usePathname } from 'lib-mui-template';

import Footer from './footer';
import Header from './header';
import { NavItemBaseProps } from './nav/types';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  headerMenu: NavItemBaseProps[];
  headerOnDark: boolean;
};

export default function MainLayout({ children, headerMenu, headerOnDark }: Props) {
  const pathname = usePathname();

  const homePage = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header menu={headerMenu} headerOnDark={headerOnDark} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!homePage && {
            pt: { xs: 8, md: 10 },
          }),
        }}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  );
}
