import ReactDOM from "react-dom/client";
import { ReactNode, Suspense } from "react";
import { HashRouter } from "react-router-dom";
import { AppRegistry } from "./app-main";
import { AppRegistryProvider } from "./app-context";
import AppDecorator from "./app-decorator";
import AppRoutes from "./navigation/routing";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  SnackbarProvider,
  LoadingScreen,
  MotionLazy,
  ProgressBar,
  SettingsDrawer,
  SettingsProvider,
  ThemeProvider,
} from "lib-mui-template";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AuthProviderFactory from "./auth/auth-provider-factory";

{
  /* <LazyLoadImage /> */
}

class AppView {
  private root: ReactDOM.Root;

  constructor(
    private readonly appRegistry: AppRegistry,
    elementId: string = "root"
  ) {
    this.root = ReactDOM.createRoot(
      document.getElementById(elementId) as HTMLElement
    );
  }

  render(decorators: AppDecorator[], renderAuthProvider: AuthProviderFactory) {
    const lazy = <LazyLoadImage />; //Workaround to avoid unused import error
    const AuthProvider = renderAuthProvider.create();
    const wrappedContent = decorators.reduceRight(
      (acc, decorator) => {
        return decorator.decorate(acc);
      },
      <MainContentWrapper />
    );

    this.root.render(
      <AuthProvider>
        <AppRegistryProvider appRegistry={this.appRegistry}>
          <GlobalProvidersWrapper>{wrappedContent}</GlobalProvidersWrapper>
        </AppRegistryProvider>
      </AuthProvider>
    );
  }
}

export default AppView;

function MainContentWrapper() {
  return (
    <MotionLazy>
      <SnackbarProvider>
        <SettingsDrawer />
        <ProgressBar />
        <AppRoutes />
      </SnackbarProvider>
    </MotionLazy>
  );
}

function GlobalProvidersWrapper({ children }: { children: ReactNode }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SettingsProvider
        defaultSettings={{
          themeMode: "light", // 'light' | 'dark'
          themeDirection: "ltr", //  'rtl' | 'ltr'
          themeContrast: "default", // 'default' | 'bold'
          themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          {/* <BrowserRouter basename={this.appRegistry.appConfig.basename}> */}
          <HashRouter>
            <Suspense fallback={<LoadingScreen />}>{children}</Suspense>
          </HashRouter>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
