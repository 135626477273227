import { ScrollProgress } from "lib-mui-template";
import { useScroll } from "framer-motion";

import Box from "@mui/material/Box";

import HomeHero from "./HomeHero";

export default function HomeView() {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <ScrollProgress scrollYProgress={scrollYProgress} />
      <HomeHero />
      <Box
        sx={{
          overflow: "hidden",
          position: "relative",
          bgcolor: "background.default",
        }}
      >

      </Box>
    </>
  );
}
