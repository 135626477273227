import React from "react";
import { useAppRegistry } from "src/app/app-context";

import HomeView from "src/app/pages/main/HomeView";

const HomePage: React.FC = () => {
  const { helmet } = useAppRegistry();
  return (
    <div>
      {helmet?.render({ title: "Home" })}
      <HomeView />
    </div>
  );
};

export default HomePage;
