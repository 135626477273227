import * as Yup from "yup";

import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import "src/app/lib/yup-costumise";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Button, Box } from "@mui/material";
import { FormProvider } from "lib-mui-template";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Unstable_Grid2";
import { RHFDatePicker } from "../components/hook-form";

// Declare the Yup schema
const schema = Yup.object().shape({
  // inquiryDate: Yup.mixed().dayjs().required("Inquiry Date is required"),
  inquiryDate: Yup.mixed().dayjs().required(),
  inquiryDate2: Yup.mixed()
    .dayjs({ min: "02/12/20224", max: "02/14/2024" })
    .required(),
});

const DataPickerTest = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      inquiryDate: undefined, // Default to current date or could be empty
      inquiryDate2: undefined, // Default to current date or could be empty
    },
  });

  const { control, handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    alert("Form Submitted! Check the console for form data.");
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Box display="flex" flexDirection="column" rowGap={3}>
              <Controller
                name="inquiryDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Inquiry Date"
                    format="DD/MM/YYYY"
                    value={field.value || null}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
              <RHFDatePicker
                name="inquiryDate2"
                label="Inquiry Date2"
                format="DD/MM/YYYY"
              />
              <Button type="submit" variant="contained" sx={{ marginTop: 2 }}>
                Submit
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default DataPickerTest;
