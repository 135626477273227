import { Firestore, FirestoreDataConverter } from "firebase/firestore";
import {
  Task,
  TaskStatus,
  Client,
  Order,
  Product,
  Feedback,
  Ingredient,
  Recipe,
  Inventory,
  BakingPlan,
  ProductDisplay,
} from "lib-shared-code";
import { genericRepositoryFactory } from "./generic-repository-factory";
import { orderConverter } from "./converters/order-converter";
import {
  TaskRepository,
  ClientRepository,
  OrderRepository,
  ProductRepository,
  FeedbackRepository,
  GenericRepository,
} from "src/app/data/repositories";
import { clientConverter } from "./converters/client-converter";
import { getAndIncrementCounter } from "./counters";
import { taskConverter } from "./converters/task-converter";
import { productConverter } from "./converters/product-converter";
import { recipeConverter } from "./converters/recipe-converter";
import { productDisplayConverter } from "./converters/product-display-converter";
import { feedbackConverter } from "./converters/feedback-converter";
import { ingredientConverter } from "./converters/ingredient-converter";
import { PartialConverter } from "./types";
import { inventoryConverter } from "./converters/invetory-converter";
import { bakingPlanConverter } from "./converters/baking-plan-converter";

export function getOrderRepository(
  db: Firestore,
  bakeryIdGetter: () => string
): OrderRepository {
  const repository = genericRepositoryFactory<Order>(
    db,
    "orders",
    orderConverter,
    bakeryIdGetter
  );

  const create = repository.create;
  const createModified = async (data: Omit<Order, "id">) => {
    const orderNumber = await getAndIncrementCounter(
      db,
      bakeryIdGetter(),
      "orders"
    );
    data.orderNumber = orderNumber;
    data.previouslyUpdatedOrderStatus = data.orderStatus;
    return create(data);
  };
  repository.create = createModified;

  const update = repository.update;
  const updateModified = async (id: string, data: Partial<Order>) => {
    if (data.orderStatus) {
      data.previouslyUpdatedOrderStatus = data.orderStatus;
    }
    update(id, data);
  };
  repository.update = updateModified;

  return repository;
}

type GetRepositoryFunction<T> = (
  db: Firestore,
  bakeryIdGetter: () => string
) => GenericRepository<T>;

// Generic function to get a repository for any entity type
function getRepository<T>(
  entityType:
    | "tasks"
    | "feedbacks"
    | "clients"
    | "products"
    | "ingredients"
    | "recipes"
    | "inventories"
    | "bakingPlans"
    | "productDisplays",
  db: Firestore,
  bakeryIdGetter: () => string,
  converter: FirestoreDataConverter<T> & PartialConverter<T> // Assuming this is the correct type for your converters
): GenericRepository<T> {
  return genericRepositoryFactory<T>(db, entityType, converter, bakeryIdGetter);
}

// Example usage for specific repositories
export const getTaskRepository: GetRepositoryFunction<Task> = (
  db,
  bakeryIdGetter
) => getRepository<Task>("tasks", db, bakeryIdGetter, taskConverter);

export const getFeedbackRepository: GetRepositoryFunction<Feedback> = (
  db,
  bakeryIdGetter
) =>
  getRepository<Feedback>("feedbacks", db, bakeryIdGetter, feedbackConverter);

export const getClientRepository: GetRepositoryFunction<Client> = (
  db,
  bakeryIdGetter
) => getRepository<Client>("clients", db, bakeryIdGetter, clientConverter);

export const getProductRepository: GetRepositoryFunction<Product> = (
  db,
  bakeryIdGetter
) => getRepository<Product>("products", db, bakeryIdGetter, productConverter);

export const getRecipeRepository: GetRepositoryFunction<Recipe> = (
  db,
  bakeryIdGetter
) => getRepository<Recipe>("recipes", db, bakeryIdGetter, recipeConverter);

export const getProductDisplayRepository: GetRepositoryFunction<ProductDisplay> = (
  db,
  bakeryIdGetter
) => getRepository<ProductDisplay>("productDisplays", db, bakeryIdGetter, productDisplayConverter);

export const getBakingPlanRepository: GetRepositoryFunction<BakingPlan> = (
  db,
  bakeryIdGetter
) =>
  getRepository<BakingPlan>(
    "bakingPlans",
    db,
    bakeryIdGetter,
    bakingPlanConverter
  );

export const getIngredientRepository: GetRepositoryFunction<Ingredient> = (
  db,
  bakeryIdGetter
) =>
  getRepository<Ingredient>(
    "ingredients",
    db,
    bakeryIdGetter,
    ingredientConverter
  );

export const getInventoryRepository: GetRepositoryFunction<Inventory> = (
  db,
  bakeryIdGetter
) =>
  getRepository<Inventory>(
    "inventories",
    db,
    bakeryIdGetter,
    inventoryConverter
  );
