import {
  Task,
  TaskStatus,
  Client,
  ClientAddress,
  ClientEngagementLevel,
  Order,
  OrderSnapshot,
  OrderStatus,
  TaskCategory,
  clientContactSnapshotToString,
} from "lib-shared-code";
import {
  TaskRepository,
  ClientRepository,
  OrderRepository,
} from "../data/repositories";
import { OrderBusinessLogic } from "./app-business-logic";
import { Firestore, or, where } from "firebase/firestore";
import {
  getOrderRepository,
  getClientRepository,
  getTaskRepository,
} from "src/app/lib/firebase/firebase-repositories";
import { FindByQueryFunctionReturnType } from "../lib/firebase/types";
import {
  revalidateEngagementLevel,
  toClientSnapshot,
} from "./utils/client-transforms";
import {
  getFirstAndLastOrderDates,
  toOrderSnapshot,
} from "./utils/order-transforms";
import { get } from "lodash";
import dayjs from "dayjs";
import { isOrderStatusChanged } from "./utils/order-status-change";

export default class OrderBusinessLogicImpl implements OrderBusinessLogic {
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new OrderBusinessLogicImpl(
      getOrderRepository(db, bakeryIdGetter),
      getClientRepository(db, bakeryIdGetter),
      getTaskRepository(db, bakeryIdGetter)
    );
  }

  constructor(
    private readonly orderRepository: OrderRepository,
    private readonly clientRepository: ClientRepository,
    private readonly taskRepository: TaskRepository
  ) {
    if (orderRepository === null) {
      throw new Error("orderRepository is null");
    }
    if (clientRepository === null) {
      throw new Error("clientRepository is null");
    }
    if (taskRepository === null) {
      throw new Error("taskRepository is null");
    }
  }

  recalculateOrder = (order: Order): Order => {
    if (order.items === null || order.items.length === 0) {
      return order;
    }
    const subTotal = order.items.reduce(
      (acc, item) => acc + item.pricePerUnit * item.quantity,
      0
    );
    const totalAmount = subTotal - order.discount + order.shipping;
    const totalItems = order.items.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
    return { ...order, subTotal, totalAmount, totalItems };
  };

  getOrders = async (): Promise<Order[]> => {
    const result = await this.orderRepository.findByQuery({
      sortBy: "orderDate",
      sortOrder: "desc",
    });
    
    return result.data;
    // return this.orderRepository.findAll();
  };

  getOrdersByStatus = async (
    status: OrderStatus | OrderStatus[]
  ): Promise<Order[]> => {
    if (Array.isArray(status)) {
      const result = await this.orderRepository.findByQuery({
        sortBy: "orderDate",
        sortOrder: "desc",
        additionalQueries: [where("orderStatus", "in", status)],
      });
      return result.data;
    } else {
      const result = await this.orderRepository.findByQuery({
        sortBy: "orderDate",
        sortOrder: "desc",
        additionalQueries: [where("orderStatus", "==", status)],
      });
      return result.data;
    }

    // return this.orderRepository.findAll();
  };

  getClientOrders = async ({
    clientId,
  }: {
    clientId: string;
  }): Promise<FindByQueryFunctionReturnType<Order>> => {
    return this.orderRepository.findByQuery({
      additionalQueries: [where("clientId", "==", clientId)],
      sortBy: "orderDate",
      sortOrder: "desc",
    });

    // additionalQueries?: QueryConstraint[];
    // sortBy?: string;
    // sortOrder?: OrderByDirection;
  };

  addOrder = async (order: Order): Promise<string> => {
    const client = await this.clientRepository.findByIdOrThrowError(
      order.clientId
    );
    order = this.recalculateOrder(order);
    order.clientSnapshot = toClientSnapshot(client);
    const orderId = await this.orderRepository.create(order);
    order.id = orderId;
    this.updateClientLastOrders(client, toOrderSnapshot(order));
    await this.updateClientAfterOrderUpdated(client);
    return orderId;
  };

  updateOrder = async (id: string, order: Partial<Order>): Promise<void> => {
    console.error("updateOrder", id, order);
    throw new Error("Method depracted use updateOrderWithClient.");
    // this.orderRepository.update(id, order);
  };

  updateOrderPartially = async (
    id: string,
    order: Partial<Order>
  ): Promise<void> => {
    this.orderRepository.update(id, order);
  };

  updateOrderWithClient = async (order: Order): Promise<void> => {
    if (!order.id) throw new Error("Order id is required");
    const client = await this.clientRepository.findByIdOrThrowError(
      order.clientId
    );
    this.updateClientLastOrders(client, toOrderSnapshot(order));
    await this.updateClientAfterOrderUpdated(client);
    const _order = this.recalculateOrder(order);
    _order.clientSnapshot = toClientSnapshot(client);
    if (
      isOrderStatusChanged(_order) &&
      order.orderStatus == OrderStatus.Delivered
    ) {
      this.onOrderStatusChangedToDelivered(_order);
    }
    this.orderRepository.update(order.id, _order);
  };

  deleteOrder = async (id: string): Promise<void> =>
    this.orderRepository.deleteById(id);

  deleteOrderAndUpdateClient = async (id: string): Promise<void> => {
    const order = await this.orderRepository.findByIdOrThrowError(id);
    const client = await this.clientRepository.findByIdOrThrowError(
      order.clientId
    );
    client.lastOrders = client.lastOrders?.filter((o) => o.orderId !== id);
    await this.updateClientAfterOrderUpdated(client);
    await this.orderRepository.deleteById(id);
  };

  getOrder = async (id: string): Promise<Order | null> =>
    this.orderRepository.findById(id);

  getOrderWithClient = async (
    id: string
  ): Promise<{ order: Order; client: Client } | null> => {
    const order = await this.orderRepository.findById(id);

    if (order) {
      const client = await this.clientRepository.findById(order?.clientId);
      if (client) {
        return { order, client };
      } else {
        throw new Error(`Client with id ${order.clientId} not found`);
      }
    }
    return null;
  };

  repeatOrder = async (id: string): Promise<string> => {
    const order = await this.orderRepository.findByIdOrThrowError(id);
    const newOrder: Order = {
      id: "",
      items: order.items,
      clientId: order.clientId,
      orderStatus: OrderStatus.ToConfirm,
      orderDate: dayjs(),
      notes: order.notes,
      orderNumber: "", // set by the repository
      subTotal: 0,
      totalAmount: 0,
      totalItems: 0,
      discount: 0,
      taxes: 0,
      shipping: 0,
    };
    const orderId = await this.addOrder(newOrder);
    return orderId;
  };

  shiftOrderStatus = async (id: string): Promise<Order | null> => {
    const order = await this.orderRepository.findById(id);
    if (
      order &&
      order.orderStatus !== OrderStatus.Cancelled &&
      order.orderStatus !== OrderStatus.Delivered
    ) {
      const status: Partial<Order> = { orderStatus: OrderStatus.Delivered };
      const statuses = [
        OrderStatus.ToConfirm,
        OrderStatus.Confirmed,
        OrderStatus.Scheduled,
        OrderStatus.Prepared,
        OrderStatus.Delivered,
      ];
      const index = statuses.indexOf(order.orderStatus);
      if (index !== -1) {
        status.orderStatus = statuses[index + 1];
        console.log("shiftOrderStatus -> status", status);
        console.log("shiftOrderStatus -> order", order);
        console.log(
          "shiftOrderStatus -> isOrderStatusChanged",
          isOrderStatusChanged(order, status.orderStatus)
        );
        if (
          isOrderStatusChanged(order, status.orderStatus) &&
          status.orderStatus === OrderStatus.Delivered
        ) {
          this.onOrderStatusChangedToDelivered(order);
          if (!order.deliveryDate) {
            status.deliveryDate = dayjs();
          }
        }
        await this.orderRepository.update(id, status);
        return { ...order, ...status };
      } else {
        throw new Error(`Invalid order status: ${order.orderStatus}`);
      }
    }
    return order;
  };

  private async updateClientLastOrders(
    client: Client,
    orderSnapshot: OrderSnapshot
  ): Promise<void> {
    const idx = client.lastOrders.findIndex(
      (o) => o.orderId === orderSnapshot.orderId
    );
    if (idx !== -1) {
      client.lastOrders[idx] = orderSnapshot;
    } else {
      client.lastOrders.push(orderSnapshot);
    }
  }

  private async updateClientAfterOrderUpdated(client: Client): Promise<void> {
    const { firstOrderDate, lastOrderDate } = getFirstAndLastOrderDates(
      client.lastOrders
    );
    if (firstOrderDate) client.firstOrderDate = firstOrderDate;
    if (lastOrderDate) client.lastOrderDate = lastOrderDate;
    client.engagementLevel = revalidateEngagementLevel(client);
    await this.clientRepository.update(client.id, client);
  }

  onOrderStatusChangedToDelivered = async (order: Order): Promise<void> => {
    console.log("onOrderStatusChangedToDelivered -> order", order);
    const newTask: Omit<Task, "id"> = {
      orderId: order.id,
      clientId: order.clientId,
      status: TaskStatus.Pending,
      category: TaskCategory.FeedbackRequest,
      scheduledDate: dayjs().add(2, "day"),
      description:
        "Request feedback from the client: " +
        clientContactSnapshotToString(order.clientSnapshot, "compact") +
        " for the Order: " +
        order.orderNumber +
        ".",
    };
    console.log("onOrderStatusChangedToDelivered -> newTask", newTask);
    await this.taskRepository.create(newTask);
  };
}
