import { NavItemBaseProps, SvgColor } from "lib-mui-template";
import { paths } from "../paths";
import { NavStructureProps } from "../../layouts/dashboard/types";
import { MENU_ICONS } from "src/app/components/icons";

export const testMenu: NavStructureProps = [
  // OVERVIEW
  // ----------------------------------------------------------------------
 
  {
    subheader: "Test Area",
    items: [
      {
        title: "Data Picker",
        path: "datapicker",
        icon: MENU_ICONS.menuItem,
        // children: [
        //   { title: "list", path: paths.dashboard.client.root },
        //   { title: "new", path: paths.dashboard.client.new },
        // ],
      },
     
    ],
  },

];
