import { Inventory } from "lib-shared-code";
import { InventoryRepository } from "src/app/data/repositories";
import { InventoryBusinessLogic } from "./app-business-logic";
import { getInventoryRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";
import dayjs from "dayjs";

export default class InventoryBusinessLogicImpl
  implements InventoryBusinessLogic
{
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new InventoryBusinessLogicImpl(
      getInventoryRepository(db, bakeryIdGetter)
    );
  }

  constructor(private readonly inventoryRepository: InventoryRepository) {
    if (inventoryRepository === null) {
      throw new Error("inventoryRepository is null");
    }
  }

  getInventorys = async (): Promise<Inventory[]> => {
    return this.inventoryRepository.findAll();
  };

  addInventory = async (inventory: Omit<Inventory, "id">): Promise<string> => {
    return this.inventoryRepository.create(inventory);
  };

  updateInventory = async (
    id: string,
    inventory: Partial<Inventory>
  ): Promise<void> => {
    return this.inventoryRepository.update(id, inventory);
  };

  deleteInventory = async (id: string): Promise<void> => {
    return this.inventoryRepository.deleteById(id);
  };

  getInventory = async (id: string): Promise<Inventory | null> => {
    return this.inventoryRepository.findById(id);
  };

  getOrCreateDefaultInventory = async (): Promise<Inventory | null> => {
    const inventories = await this.inventoryRepository.findAll();
    if (inventories && inventories.length > 0) {
      return inventories[0];
    } else {
      const inventory: Inventory = {
        id: "",
        lastReconciliation: dayjs(),
        lastUpdated: dayjs(),
        ingredientsStock: {},
      };
      const id = await this.inventoryRepository.create({
        lastReconciliation: dayjs(),
        lastUpdated: dayjs(),
        ingredientsStock: {},
      });
      inventory.id = id;
      return inventory;
    }
  };
}
