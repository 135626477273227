import { ReactElement } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { LibHelmet, LibHelmetProps } from "src/app/lib/helmet/lib-helmet";

export class LibHelmetWebImpl implements LibHelmet {
  constructor() {}

  render(props: LibHelmetProps): ReactElement<LibHelmetProps> {
    const copiedProps = { ...props };
    if (copiedProps.title) {
      copiedProps.title += " | Bakery Suite";
    } else {
      copiedProps.title = "Bakery Suite";
    }

    return <Helmet {...copiedProps} />;
  }

  decorate(element: React.ReactElement): React.ReactElement {
    return <HelmetProvider>{element}</HelmetProvider>;
  }
}
