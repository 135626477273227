import { Product } from "lib-shared-code";
import { ProductRepository } from "src/app/data/repositories";
import { ProductBusinessLogic } from "./app-business-logic";
import { getProductRepository } from "src/app/lib/firebase/firebase-repositories";
import { Firestore } from "firebase/firestore";

export default class ProductBusinessLogicImpl implements ProductBusinessLogic {
  static create(db: Firestore, bakeryIdGetter: () => string) {
    return new ProductBusinessLogicImpl(
      getProductRepository(db, bakeryIdGetter)
    );
  }

  constructor(private readonly productRepository: ProductRepository) {
    if (productRepository === null) {
      throw new Error("productRepository is null");
    }
  }

  getProducts = async (): Promise<Product[]> => {
    return this.productRepository.findAll();
  };

  addProduct = async (product: Omit<Product, "id">): Promise<string> => {
    return this.productRepository.create(product);
  };

  updateProduct = async (
    id: string,
    product: Partial<Product>
  ): Promise<void> => {
    return this.productRepository.update(id, product);
  };

  deleteProduct = async (id: string): Promise<void> => {
    return this.productRepository.deleteById(id);
  };

  getProduct = async (id: string): Promise<Product | null> => {
    return this.productRepository.findById(id);
  };
}
