import { Firestore, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";

export async function getAndIncrementCounter(
  firestore: Firestore,
  bakeryId: string,
  counterId: string
) {
  const counterRef = doc(firestore, "counters", bakeryId);

  // Attempt to get the current value of the counter
  const docSnap = await getDoc(counterRef);

  // Check if the document exists
  if (!docSnap.exists()) {
    // If the document does not exist, create it with the initial counter value
    try {
      await setDoc(counterRef, {
        [counterId]: 1, // Set the initial counter value to 1
        bakeryId // Store the bakeryId if needed, though it's redundant since it's already the document ID
      });
      return 1; // Return the initial value
    } catch (error) {
      console.error("Failed to create initial counter document:", error);
      throw error; // Rethrow or handle the error as needed
    }
  } else {
    // If the document exists, increment the counter
    const currentCount = docSnap.data()[counterId] || 0;
    const newCount = currentCount + 1;

    try {
      await updateDoc(counterRef, {
        [counterId]: newCount,
      });
      return newCount; // Return the new counter value
    } catch (error) {
      console.error("Failed to increment counter:", error);
      throw error; // Rethrow or handle the error as needed
    }
  }
}
