import { Suspense, lazy } from "react";
import { Navigate, Outlet, useRoutes, useLocation } from "react-router-dom";
import Error404Page from "../pages/Error404Page";
import { SplashScreen } from 'src/app/components/processing';

import CompactLayout from "src/app/layouts/compact";
import { authRoutes } from "./sections/auth-routes";
import { dashboardRoutes } from "../pages/dashboard/routes";
import { mainRoutes } from "./sections/main-routes";
import { testRoutes } from "./sections/test-routes";
// import PrintProductsLabels from "../pages/dashboard/orders/print/PrintProductsLabels";

const AppRoutes = () => {
  let location = useLocation(); // Hook to access current location

  return useRoutes([
    ...mainRoutes,
    ...authRoutes,
    ...dashboardRoutes,
    ...testRoutes,

    {
      element: (
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      ),
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        // { path: '500', element: <Page500 /> },
        { path: "404", element: <Error404Page /> },
        // <Route path="*" element={<Navigate to="/404" replace state={{ from: location.pathname }} />} />

        // { path: '403', element: <Page403 /> },
      ],
    },
    

    {
      element: (
        <div>
            <Outlet /> 
        </div>
      ),
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        // { path: '500', element: <Page500 /> },
        // { path: "print", element: <PrintProductsLabels /> },
        // <Route path="*" element={<Navigate to="/404" replace state={{ from: location.pathname }} />} />

        // { path: '403', element: <Page403 /> },
      ],
    },


    // Fallback 404 page
    {
      path: "*",
      element: (
        <Navigate to="/404" replace state={{ from: location.pathname }} />
      ),
    },
  ]);
};

export default AppRoutes;
