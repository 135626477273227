import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { PartialConverter } from "../types";

export function genericConverter<T extends { id?: string }>(): FirestoreDataConverter<T> & PartialConverter<T> {
  return {
    toFirestorePartial(item: Partial<T>): DocumentData {
      const updateData: DocumentData = { ...item };
      return updateData;
    },
    toFirestore(item: T): DocumentData {
      const firestoreData: DocumentData = { ...item };
      return firestoreData;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot): T {
      const data = snapshot.data();
      return {
        id: snapshot.id,
        ...data,
      } as T;
    },
  };
}
