import { Suspense, lazy } from "react";
import { Link, Navigate, Outlet, useRoutes } from "react-router-dom";

import PlaceHolderPage from "src/app/pages/PlaceHolderPage";
// import Error404Page from "../pages/Error404Page";
// import { AuthGuard } from "../auth/guard";
import { LoadingScreen, SplashScreen } from "lib-mui-template";
import CompactLayout from "src/app/layouts/compact";
import DashboardLayout from "src/app/layouts/dashboard";
import { useAuthContext } from "src/app/auth/hooks";
import { NavConfig } from "src/app/layouts/dashboard/types";
import { testMenu } from "./test-menu";
import { AuthGuard } from "src/app/auth/guard";
import ClientsAddPage from "src/app/pages/dashboard/clients/add/ClientsAddPage";
import { paths, getSubPath } from "../paths";
import ClientsListPage from "src/app/pages/dashboard/clients/list/ClientsListPage";
import ClientsEditPage from "src/app/pages/dashboard/clients/edit/ClientsEditPage";
import ClientsViewPage from "src/app/pages/dashboard/clients/view/ClientsViewPage";
import ClientsAddOrderPage from "src/app/pages/dashboard/clients/addOrder/ClientsAddOrderPage";
import DataPickerTest from "src/app/test/DataPickerTest";
// import { authRoutes } from "./sections/authRoutes";
// import { NavConfig } from "../layouts/common/types";
// import {menu} from './menu'
const UserProfilePage = lazy(
  () => import("src/app/pages/dashboard/user/UserProfilePage")
);

const dashboardNavConfig: NavConfig = {
  navContent: {},
  navStructure: testMenu,
};
const root = "test";
const subPaths = {
  test: {
    root: root,
    client: {
      root: getSubPath(paths.dashboard.clients.root, root),
      add: getSubPath(paths.dashboard.clients.add, root),
      edit: getSubPath(paths.dashboard.clients.edit(), root),
      view: getSubPath(paths.dashboard.clients.view(), root),
    },
    // order: {
    //   add: getSubPath(paths.dashboard.order.new, root),
    //   view: getSubPath(paths.dashboard.clients.order.view(), root),
    // },
  },
};


export const testRoutes = [
  {
    path: root,
    element: (
      <AuthGuard>
        <DashboardLayout navConfig={dashboardNavConfig}>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Page text="Test" /> },
      { path: "datapicker", element: <DataPickerTest /> },
      // { path: "placholder", element: <PlaceHolderPage /> },
    ],
  },
];
// export default function NewClientPage() {
//
function Page({ text }: { text: string }) {
  return <div>{text}</div>;
}
