import { Firestore, FirestoreDataConverter } from "firebase/firestore";
import { GenericRepository } from "src/app/data/repositories";
import {
  GenericOperations,
  genericOperationsFactory,
} from "./generic-operations-factory";
import { FindByQueryParams, PartialConverter } from "./types";

// Define a generic type for the repository

// Factory function to create a repository for any entity type
export function genericRepositoryFactory<T>(
  db: Firestore,
  collectionName: string,
  converter: FirestoreDataConverter<T> & PartialConverter<T>,
  bakeryIdGetter: () => string
): GenericRepository<T> {
  if (!bakeryIdGetter) throw new Error("bakeryIdGetter is required");

  const operations: GenericOperations<T> = genericOperationsFactory<T>(
    db,
    collectionName,
    converter,
    bakeryIdGetter
  );

  return {
    findAll: async () => await operations.findAll(),
    findByQuery: async (params: FindByQueryParams) =>
      await operations.findByQuery(params),
    findById: async (id: string) => await operations.findById(id),
    findByIdOrThrowError: async (id: string) => {
      const data = await operations.findById(id);
      if (!data)
        throw new Error(
          `Entity with id ${id} not found in collection ${collectionName}`
        );
      return data;
    },
    create: async (data: Omit<T, "id">) => await operations.create(data),
    createById: async (id: string,data: Omit<T, "id">) => await operations.createById(id,data),
    update: async (id: string, data: Partial<T>) =>
      await operations.update(id, data),
    deleteById: async (id: string) => await operations.deleteById(id),
  };
}
